import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { ConversationReadFull } from "@/client/types.gen";

import MessageSkeleton, { Message } from "./Message";

export const ConversationSkeleton = ({
  renderDetails = true,
}: {
  renderDetails?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <MessageSkeleton renderDetails={renderDetails} />
      <MessageSkeleton renderDetails={renderDetails} />
    </div>
  );
};

export default function Conversation({
  conversation,
  renderDetails = true,
}: {
  conversation: ConversationReadFull;
  renderDetails?: boolean;
}) {
  const { user, messages } = conversation;
  const [searchParams] = useSearchParams();
  const highlightMessageId = searchParams.get("message_id");
  const messageRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const name = user?.name || "Anonymous User";

  useEffect(() => {
    if (highlightMessageId && messageRefs.current[highlightMessageId]) {
      messageRefs.current[highlightMessageId]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [highlightMessageId]);

  return (
    <div className="flex flex-col gap-1">
      {messages.map((message, index) => {
        const prevMessage = index > 0 ? messages[index - 1] : null;
        const showHeader = !prevMessage || prevMessage.role !== message.role;

        return (
          <Message
            key={message.id}
            message={message}
            conversation={conversation}
            showHeader={showHeader}
            renderDetails={renderDetails}
            user_name={name}
            isHighlighted={highlightMessageId === message.id}
            messageRef={(el) => {
              messageRefs.current[message.id] = el;
            }}
          />
        );
      })}
    </div>
  );
}
