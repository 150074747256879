import countryCodeToFlagEmoji from "country-code-to-flag-emoji";
import isEqual from "lodash/isEqual";
import { Sun, Moon, RotateCw } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { urlSafeEncode } from "@/lib/launcherSdk";
import { FrameSettingsSchema } from "@/lib/schemas";

import { useFrameEditor } from "./FrameEditorContext";
import { FrameSettingsValues } from "./FrameEditorPage";

const PreviewToolbar = ({
  currentTheme,
  currentLocale,
  locales,
  setCurrentTheme,
  setCurrentLocale,
  onReset,
}: {
  currentTheme: string;
  currentLocale: string;
  locales: Array<{ locale: string }>;
  setCurrentTheme: (theme: "light" | "dark") => void;
  setCurrentLocale: (locale: string) => void;
  onReset: () => void;
}) => {
  return (
    <div className="absolute left-2 top-0 z-10 space-y-4 rounded-lg shadow-lg backdrop-blur-sm">
      <div className="flex items-center space-x-4">
        <div className="flex items-center">
          <Select value={currentLocale} onValueChange={setCurrentLocale}>
            <SelectTrigger className="!ml-0 rounded-md border-0 bg-transparent p-2">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {locales.map((locale) => (
                <SelectItem key={locale.locale} value={locale.locale}>
                  <span className="text-xl">
                    {countryCodeToFlagEmoji(locale.locale)}
                  </span>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Button
            variant="ghost"
            size="sm"
            className="text-gray-600 hover:text-gray-700"
            onClick={() =>
              setCurrentTheme(currentTheme === "light" ? "dark" : "light")
            }
          >
            {currentTheme === "light" ? <Sun size={16} /> : <Moon size={16} />}
          </Button>
          <Button
            variant="ghost"
            size="sm"
            className="text-gray-600 hover:text-gray-700"
            onClick={onReset}
          >
            <RotateCw size={16} />
          </Button>
        </div>
      </div>
    </div>
  );
};

const useWaitForIframeReady = (onReady: () => void) => {
  const handleMessage = useCallback(
    (event: any) => {
      // In production, you should verify event.origin
      if (event.data?.type === "BOTBRAINS_EDITOR_IFRAME_READY") {
        console.log("Iframe ready");
        onReady?.();
      }
    },
    [onReady]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);
};

const usePanelState = (settings: FrameSettingsValues) => {
  const [isOpen, setIsOpen] = useState(true);
  const prevSettings = useRef(settings);
  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      const previewChanged =
        settings.show_popup !== prevSettings?.current.show_popup;
      if (previewChanged && settings.show_popup) {
        setIsOpen(false);
      }

      const doOpenOn = [
        settings.assistant_name !== prevSettings?.current.assistant_name,
        settings.header_image_url !== prevSettings?.current.header_image_url,
        !isEqual(settings.locales, prevSettings?.current.locales),
        !isEqual(settings.themes, prevSettings?.current.themes),
      ];
      if (doOpenOn.some(Boolean)) {
        setIsOpen(true);
      }
      prevSettings.current = settings;
    }
  }, [settings]);

  return isOpen;
};

export const PreviewPanel = (props: {
  frameId: string;
  frameSettings: FrameSettingsValues;
  isFormValid: boolean;
}) => {
  const { frameId, frameSettings, isFormValid } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { currentLocale, currentTheme, setCurrentLocale, setCurrentTheme } =
    useFrameEditor();

  const transformedSettings = FrameSettingsSchema.safeParse(frameSettings);
  const settings = transformedSettings.success
    ? transformedSettings.data
    : frameSettings;

  const explicitOpen = usePanelState(settings);

  const encodedState = useMemo(() => {
    const stateObj = {
      apiBase: import.meta.env.VITE_API_BASE_URL,
      frameId: frameId,
      frameSettings: settings,
      theme: currentTheme,
      locale: currentLocale,
      launcherState: {
        isShow: true,
        isOpen: explicitOpen,
        lastOpen: 0,
        openedOnce: explicitOpen,
        isPreviewVisible: !explicitOpen && settings.show_popup,
      },
    };
    return urlSafeEncode(stateObj);
  }, [frameId, settings, currentTheme, currentLocale, explicitOpen]);

  const sendUpdatedState = useCallback(() => {
    const iframe = iframeRef.current;
    if (!iframe || !iframe.contentWindow) return;
    console.log("Sending updated state to iframe");
    iframe.contentWindow?.postMessage(
      {
        type: "botbrainsHydrateState",
        payload: encodedState,
      },
      "*"
    );
  }, [encodedState]);

  useWaitForIframeReady(sendUpdatedState);
  useEffect(() => {
    const iframe = iframeRef.current;

    sendUpdatedState();

    iframe?.addEventListener("load", sendUpdatedState);
    return () => {
      iframe?.removeEventListener("load", sendUpdatedState);
    };
  }, [encodedState, sendUpdatedState]);

  const handleReset = useCallback(() => {
    if (iframeRef.current) {
      const currentSrc = iframeRef.current.src;
      iframeRef.current.src = "about:blank";
      setTimeout(() => {
        if (iframeRef.current) {
          iframeRef.current.src = currentSrc;
        }
      }, 100);
    }
  }, []);

  // edo chat.botbrains.io/v2 && npm run dev:standalone
  // const iframeUrl = "http://localhost:5174/iframe/editor";
  const iframeUrl = `${import.meta.env.VITE_CHAT_URL}/iframe/editor`;
  //HACK(memben): for some reason frameSettings was {} sometimes, not reproducible
  const showIframe = isFormValid && Object.keys(settings).length > 0;
  return (
    <div className="w-full bg-gray-100 p-2 lg:w-1/2 mb-2 rounded-lg">
      {showIframe ? (
        <div className="relative h-full" key={frameId}>
          <div className="sticky top-4 z-10">
            <PreviewToolbar
              currentTheme={currentTheme}
              currentLocale={currentLocale}
              locales={frameSettings.locales}
              setCurrentTheme={setCurrentTheme}
              setCurrentLocale={setCurrentLocale}
              onReset={handleReset}
            />
          </div>
          {/* NOTE(memben): 72 = NavigationHeader (64px) + SidebarInset (8px) */}
          <div className="sticky top-[72px] h-[calc(100vh-72px)]">
            <iframe
              key={frameId}
              ref={iframeRef}
              src={iframeUrl}
              allow="clipboard-read; clipboard-write"
              className="h-full w-full rounded-lg"
            />
          </div>
        </div>
      ) : (
        <div className="flex h-full items-center justify-center text-gray-400">
          Only valid settings can be previewed.
        </div>
      )}
    </div>
  );
};
