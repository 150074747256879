import { zodResolver } from "@hookform/resolvers/zod";
import {
  AtSign,
  ChevronsUpDown,
  FileJson,
  Hash,
  Phone,
  UserIcon,
} from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";

import { FieldContainer, InputField } from "../SharedForm";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUserMutation } from "@/client/@tanstack/react-query.gen";
import { toast } from "sonner";
import { UserRead } from "@/client";
import { UserUpdateSchema } from "@/lib/schemas";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import { Textarea } from "../ui/textarea";

export type UserValues = z.infer<typeof UserUpdateSchema>;

interface UserDialogProps {
  user: UserRead;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function UserUpdateDialog({
  user,
  open,
  onOpenChange,
}: UserDialogProps) {
  const form = useForm<UserValues>({
    resolver: zodResolver(UserUpdateSchema),
    defaultValues: {
      ...user,
      external_attributes: user.external_attributes
        ? JSON.stringify(user.external_attributes, null, 2)
        : "",
    },
    mode: "onChange",
  });

  const queryClient = useQueryClient();

  const updateUser = useMutation({
    ...updateUserMutation(),
    onSuccess: () => {
      toast("Success", {
        description: "User updated successfully",
      });
      queryClient.invalidateQueries({
        // NOTE(memben): invalidate listUsers infinite query, cannot use queryKey directly - relying on this openapi-ts structure
        predicate: (query) => {
          const queryKey = query.queryKey[0] as { _id: string };
          return queryKey._id === "listProjectUsers";
        },
      });
      queryClient.invalidateQueries({
        predicate: (query) => {
          const queryKey = query.queryKey[0] as { _id: string };
          return queryKey._id === "getConversation";
        },
      });
      onOpenChange(false);
    },
    onError: (error) => {
      toast.error("Error", {
        description: error.message,
      });
    },
  });

  const onSubmit = (values: UserValues) => {
    console.log(values);
    updateUser.mutate({
      path: { user_pool_id: user.user_pool_id, user_id: user.id },
      body: values,
    });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-2xl">
        <DialogHeader>
          <DialogTitle>Update User Information</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className=" bg-gray-50/50 p-4">
              <Collapsible>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InputField
                    form={form}
                    name="first_name"
                    icon={UserIcon}
                    label="First Name"
                    description="Enter user's first name"
                    placeholder="John"
                  />

                  <InputField
                    form={form}
                    name="last_name"
                    icon={UserIcon}
                    label="Last Name"
                    description="Enter user's last name"
                    placeholder="Doe"
                  />

                  <InputField
                    form={form}
                    name="email"
                    icon={AtSign}
                    label="Email"
                    description="Enter user's email address"
                    placeholder="john.doe@example.com"
                  />

                  <InputField
                    form={form}
                    name="phone"
                    icon={Phone}
                    label="Phone"
                    description="Enter user's phone number"
                    placeholder="+49 12 3456789"
                  />
                </div>
                <div className="mt-4 pt-2 border-t border-gray-200" />
                <div className="flex items-center">
                  <CollapsibleTrigger asChild>
                    <Button variant="ghost" size="sm">
                      <ChevronsUpDown className="h-4 w-4" />
                      <h4 className="text-sm">Advanced Settings</h4>
                    </Button>
                  </CollapsibleTrigger>
                </div>
                <CollapsibleContent className="mt-4 space-y-4">
                  <FormField
                    control={form.control}
                    name={"external_attributes"}
                    render={({ field: messageField }) => (
                      <FormItem>
                        <FieldContainer
                          icon={FileJson}
                          label="External Attributes"
                          description="Annotate arbitrary key-value pairs"
                        >
                          <FormControl>
                            <Textarea
                              {...messageField}
                              placeholder="Enter message"
                              className="min-h-[100px] rounded-md border border-gray-200 bg-white/50 px-3 py-2 shadow-sm focus:border-primary focus:ring-2 focus:ring-primary"
                            />
                          </FormControl>
                          <FormMessage className="text-xs text-red-500" />
                        </FieldContainer>
                      </FormItem>
                    )}
                  />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <InputField
                      form={form}
                      name="external_id"
                      icon={Hash}
                      label="External ID"
                      description="Enter user's external identifier"
                      placeholder="user-123"
                    />
                  </div>
                </CollapsibleContent>
              </Collapsible>
            </div>
            <DialogFooter className="sm:justify-end gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => onOpenChange(false)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="default">
                Update
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
