import { useQuery } from "@tanstack/react-query";
import { Loader2, Upload, Globe2, ScanSearch } from "lucide-react";

import {
  getDataproviderOptions,
  getDataproviderSnapshotOptions,
  listSourcesOptions,
} from "@/client/@tanstack/react-query.gen";

import { SourcesDataTable } from "./SourcesDataTable";
import { AxiosErrorBox } from "../Error";
import TableActions from "./SourceDataTableActions";

import { useState } from "react";

import { UploadDialog } from "./SourceUploadDialog";
import { useParams } from "react-router-dom";
import { DataProviderType } from "@/client";

interface EmptySourcesStateProps {
  providerId: string;
  isSnapshotPending?: boolean;
}

export const EmptySourcesState = ({
  providerId,
  isSnapshotPending = false,
}: EmptySourcesStateProps) => {
  const { projectId } = useParams();
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const {
    data: provider,
    isPending,
    error,
  } = useQuery({
    ...getDataproviderOptions({
      path: {
        project_id: Number(projectId),
        data_provider_id: providerId,
      },
    }),
  });

  if (isPending) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
      </div>
    );
  }

  if (error) {
    return AxiosErrorBox({
      error,
    });
  }

  const type = provider.data_provider_type;

  const getContent = () => {
    if (isSnapshotPending) {
      return {
        icon: <Loader2 className="h-8 w-8 animate-spin" />,
        message: "Syncing sources...",
        description:
          type === "webcrawler"
            ? "Crawling website for content"
            : "Processing documents",
      };
    }

    const content: Record<
      DataProviderType | "default",
      {
        icon: JSX.Element;
        message: string;
        description: string;
      }
    > = {
      webcrawler: {
        icon: <Globe2 className="h-8 w-8" />,
        message: "No sources indexed",
        description: "Start a sync to crawl website content",
      },
      human: {
        icon: <Upload className="h-8 w-8" />,
        message: "No documents uploaded",
        description: "Upload documents to get started",
      },
      confluence: {
        icon: <ScanSearch className="h-8 w-8" />,
        message: "No documents indexed",
        description: "Start a sync to index content",
      },
      default: {
        icon: <ScanSearch className="h-8 w-8" />,
        message: "No sources indexed",
        description: "Start a sync to index content",
      },
    };

    return content[provider.data_provider_type] || content.default;
  };

  const { icon, message, description } = getContent();

  const handleClick = () => {
    if (type === "human" && provider) {
      setShowUploadDialog(true);
    }
  };

  return (
    <>
      <div
        className={`flex flex-col items-center justify-center py-8 text-gray-500 border-dotted border-2 border-gray-200 rounded-lg ${
          type === "human" ? "cursor-pointer hover:border-gray-300" : ""
        }`}
        onClick={handleClick}
      >
        <div className="mb-2">{icon}</div>
        <p className="text-base font-medium">{message}</p>
        <p className="text-sm text-gray-400 mt-1">{description}</p>
      </div>

      <UploadDialog
        provider={provider}
        open={showUploadDialog}
        onOpenChange={setShowUploadDialog}
      />
    </>
  );
};

export default function SourcesList({
  projectId,
  providerId,
  snapshotId,
  allowMutations = false,
}: {
  projectId: number;
  providerId: string;
  snapshotId: string;
  allowMutations?: boolean;
}) {
  const {
    data: provider,
    isPending: providerIsPending,
    error: providerError,
  } = useQuery({
    ...getDataproviderOptions({
      path: {
        project_id: Number(projectId),
        data_provider_id: providerId,
      },
    }),
  });

  const {
    data: snapshot,
    isPending: snapshotIsPending,
    error: snapshotError,
  } = useQuery({
    ...getDataproviderSnapshotOptions({
      path: {
        project_id: projectId,
        data_provider_id: providerId,
        data_provider_snapshot_id: snapshotId,
      },
    }),
  });

  const {
    data: sources,
    isPending: sourcesPending,
    error: sourcesError,
  } = useQuery({
    ...listSourcesOptions({
      path: {
        project_id: projectId,
      },
      query: {
        data_provider_snapshot_id: snapshotId,
      },
    }),
  });

  if (providerIsPending || snapshotIsPending || sourcesPending) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
      </div>
    );
  }

  if (providerError || snapshotError || sourcesError) {
    const error = providerError || snapshotError || sourcesError;
    if (!error) throw new Error("Unexpected error state");
    return AxiosErrorBox({
      error,
    });
  }

  if (!sources?.sources.length) {
    return (
      <EmptySourcesState
        providerId={providerId}
        isSnapshotPending={snapshot.status === "PENDING"}
      />
    );
  }

  if (provider.data_provider_type === "human") {
    return (
      <SourcesDataTable
        data={sources.sources}
        actions={TableActions}
        columnVisibility={{
          select: allowMutations,
          status: false,
          resource_id: false,
        }}
      />
    );
  }

  return (
    <SourcesDataTable
      data={sources.sources}
      columnVisibility={{ select: false, status: false }}
    />
  );
}
