import { ChevronLeft, ChevronRight } from "lucide-react";

import { AxiosErrorBox } from "../Error";
import { Button } from "../ui/button";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getConversationNeighborsOptions,
  getConversationOptions,
} from "@/client/@tanstack/react-query.gen";

import { Skeleton } from "@/components/ui/skeleton";
import { useEffect } from "react";
import {
  nullableDateHandler,
  stringArrayHandler,
  useQueryParam,
} from "@/hooks/useQueryState";
import LinkWithQuery from "../LinkWithQuery";

export function NextPrevConversations({
  projectId,
  conversationId,
}: {
  projectId: number;
  conversationId: string;
}) {
  const aliases = useQueryParam("alias", [], stringArrayHandler);
  const since = useQueryParam("since", null, nullableDateHandler);
  const until = useQueryParam("until", null, nullableDateHandler);
  const topics = useQueryParam("topic", [], stringArrayHandler);
  const userIds = useQueryParam("user", [], stringArrayHandler);

  const queryClient = useQueryClient();
  const { data, isPending, error } = useQuery({
    ...getConversationNeighborsOptions({
      path: {
        project_id: projectId,
        conversation_id: conversationId,
      },
      query: {
        ...(since ? { since } : {}),
        ...(until ? { until } : {}),
        aliases: aliases,
        topics: topics,
        user_ids: userIds,
      },
    }),
  });

  // Prefetch hook
  useEffect(() => {
    if (data) {
      if (data.prev) {
        queryClient.prefetchQuery({
          ...getConversationOptions({
            path: {
              project_id: projectId,
              conversation_id: data.prev.id,
            },
          }),
        });
      }
      if (data.next) {
        queryClient.prefetchQuery({
          ...getConversationOptions({
            path: {
              project_id: projectId,
              conversation_id: data.next.id,
            },
          }),
        });
      }
    }
  }, [data, projectId, queryClient]);

  if (isPending) {
    return (
      <div className="flex flex-row gap-2">
        <Skeleton className="h-10 w-10 rounded-md" />
        <Skeleton className="h-10 w-10 rounded-md" />
      </div>
    );
  }
  if (error) return <AxiosErrorBox error={error} />;
  const prev = data.prev;
  const next = data.next;

  return (
    <div className="flex flex-row gap-2">
      {prev ? (
        <LinkWithQuery to={`/${projectId}/conversations/${prev.id}`}>
          <Button variant="outline" size="icon">
            <ChevronLeft />
          </Button>
        </LinkWithQuery>
      ) : (
        <Button variant="outline" disabled size="icon">
          <ChevronLeft />
        </Button>
      )}

      {next ? (
        <LinkWithQuery to={`/${projectId}/conversations/${next.id}`}>
          <Button variant="outline" size="icon">
            <ChevronRight />
          </Button>
        </LinkWithQuery>
      ) : (
        <Button variant="outline" disabled size="icon">
          <ChevronRight />
        </Button>
      )}
    </div>
  );
}
