import {
  type GetSourceFileData,
  type GetSourceFileError,
} from "@/client/types.gen";
import { client } from "@/client/client.gen";
import { Options } from "@/client/sdk.gen";

export const getSourceFile = <ThrowOnError extends boolean = false>(
  options: Options<GetSourceFileData, ThrowOnError>
) => {
  return (options.client ?? client).get<Blob, GetSourceFileError, ThrowOnError>(
    {
      ...options,
      url: "/v1/projects/{project_id}/sources/{source_id}/file",
      responseType: "blob",
    }
  );
};
