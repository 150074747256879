import { useCurrentProjectBreadcrumbs } from "@/hooks";
import NavigationHeader from "../sidebar/NavigationHeader";
import PageHeader from "../PageHeader";
import QueryDateRangePicker from "../QueryDateRangePicker";
import TopicChart from "./TopicChart";
import { TopicsVisualizationButton } from "./TopicsVisualizationPage";
import TopicCards from "./TopicCards";

export default function TopicsPage() {
  const breadcrumbs = [...useCurrentProjectBreadcrumbs(), { label: "Topics" }];
  const groupingInterval = "7d";

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <PageHeader
        title="Topics"
        subtitle="TopicAI detects topics every Monday morning, and conversations are assigned daily based on Monday's model. A conversation's topic is final if it started before the last Monday."
      />
      <main className="space-y-6">
        <div className="mt-4">
          <div className="flex flex-col justify-between gap-4 sm:flex-row">
            <TopicsVisualizationButton />
            <QueryDateRangePicker initialPreset="last90" />
          </div>
        </div>
        <TopicChart groupingInterval={groupingInterval} />
        <TopicCards groupingInterval={groupingInterval} />
      </main>
    </>
  );
}
