import { AxiosError } from "axios";
import { XCircle } from "lucide-react";

import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { getErrorMessages } from "@/lib/utils";

import { HttpValidationError } from "../client/types.gen";

interface AlertProps {
  title: string;
  bullets: string[];
}

export function AxiosErrorBox({
  title = "Failed to load.",
  error,
}: {
  title?: string;
  error: AxiosError<HttpValidationError, any>;
}) {
  return <ErrorBox title={title} bullets={getErrorMessages(error)} />;
}

export default function ErrorBox({ title, bullets }: AlertProps) {
  return (
    <Alert variant="destructive" className="relative">
      <XCircle className="h-4 w-4" />
      <AlertTitle>{title}</AlertTitle>
      {bullets.length > 0 && (
        <AlertDescription>
          <ul className="list-disc pl-5 space-y-1 mt-2">
            {bullets.map((bullet, index) => (
              <li key={index}>{bullet}</li>
            ))}
          </ul>
        </AlertDescription>
      )}
    </Alert>
  );
}
