// hooks/useInfiniteScroll.ts
import { useEffect, useRef } from "react";
import { UseInfiniteQueryResult } from "@tanstack/react-query";

export function useInfiniteScroll<T>({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: Pick<
  UseInfiniteQueryResult<T>,
  "fetchNextPage" | "hasNextPage" | "isFetchingNextPage"
>) {
  const observerTarget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return observerTarget;
}
