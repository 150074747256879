import { ArrowUpRight, Flag, MessageCircle } from "lucide-react";
import { useParams } from "react-router-dom";

import {
  useConversation,
  useCurrentProjectBreadcrumbs,
  useTopicColor,
} from "../../hooks";
import { AxiosErrorBox } from "../Error";
import UserCard, { UserCardSkeleton } from "../users/UserCard";
import Conversation, { ConversationSkeleton } from "./Conversation";
import NavigationHeader, {
  NavigationHeaderSkeleton,
} from "../sidebar/NavigationHeader";
import PageHeader, { PageHeaderSkeleton } from "../PageHeader";
import { ConversationSnapshotRead, Topic } from "@/client";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { Button } from "../ui/button";
import { Tags } from "./Tags";

import { Skeleton } from "@/components/ui/skeleton";
import { NextPrevConversations } from "./NextPrevConversations";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getConversationOptions,
  listTopicsOptions,
  updateConversationSnapshotMutation,
} from "@/client/@tanstack/react-query.gen";
import { toast } from "sonner";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

const ConversationPageSkeleton = () => {
  return (
    <>
      <NavigationHeaderSkeleton />
      <header className="space-y-2">
        <PageHeaderSkeleton hasSubtitle hasChildren />
      </header>

      <div className="w-full mt-6">
        <div className="flex flex-col lg:flex-row gap-6">
          <main className="flex-grow">
            <ConversationSkeleton renderDetails />
          </main>
          {/* HACK(memben): 23px for alignment with Conversation Card */}
          <aside className="w-full lg:w-80 flex-shrink-0 mt-[23px]">
            <div className="lg:sticky lg:top-6 space-y-2">
              <UserCardSkeleton showDevices />
              <div className="space-y-2">
                <div className="w-full p-3 bg-white rounded-lg border border-gray-200 flex items-center justify-center">
                  <Skeleton className="h-5 w-28" />
                </div>
                <div className="w-full p-3 bg-red-500/10 rounded-lg border border-red-200 flex items-center justify-center">
                  <Skeleton className="h-5 w-24" />
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};

const formatConversationDate = (date: Date) => {
  return new Intl.DateTimeFormat("default", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(date));
};

const EditableTopicTag = ({
  snapshot,
  topic,
}: {
  snapshot: ConversationSnapshotRead;
  topic: Topic;
}) => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    ...listTopicsOptions({
      path: {
        project_id: Number(projectId),
      },
    }),
  });

  const topics = data?.data ?? [];

  const updateSnapshot = useMutation({
    ...updateConversationSnapshotMutation(),
    onSuccess: () => {
      toast("Success", {
        description: "Topic updated",
      });
      void queryClient.invalidateQueries({
        queryKey: getConversationOptions({
          path: {
            project_id: Number(projectId),
            conversation_id: snapshot.conversation_id,
          },
        }).queryKey,
      });
    },
  });

  const handleTopicChange = (newTopicId: string) => {
    updateSnapshot.mutate({
      path: {
        project_id: Number(projectId),
        conversation_snapshot_id: snapshot.id,
      },
      body: {
        id: snapshot.id,
        topics_immutable: true,
        topic_ids: [newTopicId],
      },
    });
  };

  const getTopicColor = useTopicColor();

  return (
    <TooltipProvider>
      <Tooltip delayDuration={50}>
        <Select
          defaultValue={topic.id.toString()}
          onValueChange={handleTopicChange}
        >
          <TooltipTrigger asChild>
            <SelectTrigger className="border-0 bg-transparent p-0 focus:ring-0">
              <SelectValue>
                <div
                  className="inline-flex items-center gap-1.5 rounded-full px-2.5 py-1 text-sm transition-colors hover:opacity-90"
                  style={{
                    backgroundColor: getTopicColor(topic.id).ultraLight,
                    color: getTopicColor(topic.id).ultraDark,
                    border: `1px solid ${getTopicColor(topic.id).light}`,
                  }}
                >
                  <span
                    className="inline-block h-2 w-2 rounded-full"
                    style={{ backgroundColor: getTopicColor(topic.id).main }}
                  />
                  <span className="font-medium">{topic.title}</span>
                </div>
              </SelectValue>
            </SelectTrigger>
          </TooltipTrigger>
          <SelectContent className="max-h-80 overflow-y-auto p-1">
            {topics?.map((t) => (
              <SelectItem
                key={t.id}
                value={t.id.toString()}
                className="cursor-pointer"
              >
                <div className="flex items-center gap-2">
                  <span
                    className="h-2 w-2 rounded-full"
                    style={{
                      backgroundColor: getTopicColor(t.id).main,
                    }}
                  />
                  <span className="font-medium">{t.title}</span>
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {topic.description && (
          <TooltipContent>
            <p className="max-w-xs text-sm">{topic.description}</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

export default function ConversationPage() {
  const { projectId, conversationId } = useParams();
  const {
    data: conversation,
    isPending,
    error,
  } = useConversation({
    projectId: Number(projectId),
    conversationId: conversationId as string,
  });

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { href: `/${projectId}/conversations`, label: "Conversations" },
    { label: conversation?.messages[0].text ?? "Conversation" },
  ];

  if (isPending) return <ConversationPageSkeleton />;
  if (error) return <AxiosErrorBox error={error} />;

  // NOTE(memben): ignore the previous snapshots as they do not have a complete view of the conversation
  const latestSnapshot = [...conversation.snapshots].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];
  const topics = latestSnapshot?.topics ?? [];

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <header className="space-y-2">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <PageHeader
            title="Conversation"
            subtitle={
              conversation.messages.length +
              " messages exchanged, started at " +
              formatConversationDate(conversation.first_message_time)
            }
          >
            <div className="flex gap-2">
              {topics.map((topic) => (
                <EditableTopicTag
                  key={topic.id}
                  snapshot={latestSnapshot}
                  topic={topic}
                />
              ))}
            </div>
          </PageHeader>
          <div className="flex flex-wrap gap-4 items-center">
            {conversation.frame_session && (
              <Button
                variant="default"
                onClick={() =>
                  window.open(
                    `${import.meta.env.VITE_CHAT_URL}/iframe/editor?frameId=${conversation.frame_session!.frame_id}`,
                    "_blank"
                  )
                }
              >
                Chat Now
                <ArrowUpRight className="h-4 w-4" />
              </Button>
            )}
            <NextPrevConversations
              projectId={Number(projectId)}
              conversationId={conversationId as string}
            />
          </div>
        </div>
        {conversation.tags && <Tags tags={conversation.tags} />}
      </header>

      <div className="w-full mt-6">
        <div className="flex flex-col lg:flex-row gap-6">
          <main className="flex-grow">
            <Conversation conversation={conversation} />
          </main>
          {conversation.user && (
            // NOTE(memben): 28px to align with Conversation component
            <aside className="w-full lg:w-80 flex-shrink-0 mt-[28px]">
              <div className="lg:sticky lg:top-6 space-y-2">
                <UserCard
                  user={conversation.user}
                  devices={conversation.frame_session?.devices ?? []}
                />
                <Button
                  onClick={() => {
                    window.$crisp.push(["do", "chat:open"]);
                    window.$crisp.push(["do", "chat:show"]);
                  }}
                  variant={"outline"}
                  className="w-full"
                >
                  Give Feedback
                  <MessageCircle className="h-4 w-4" />
                </Button>
                <Button
                  onClick={() => {
                    window.$crisp.push(["do", "chat:show"]);
                    window.$crisp.push(["do", "chat:open"]);
                    window.$crisp.push([
                      "do",
                      "message:send",
                      [
                        "text",
                        `Hi, könntet ihr bitte diese Nachrichten überprüfen?`,
                      ],
                    ]);
                    window.$crisp.push([
                      "do",
                      "message:show",
                      [
                        "text",
                        `Vielen Dank für deine Nachricht! 
          Wir schauen uns das an und du kannst den Fall als abgeschlossen betrachten. 
          Solltest du noch weitere Anliegen haben, kannst du uns jederzeit schreiben – 
          wir melden uns dann so schnell wie möglich! 😊`,
                      ],
                    ]);
                  }}
                  variant={"destructive"}
                  className=" w-full"
                >
                  Report Spam
                  <Flag className="h-4 w-4" />
                </Button>
              </div>
            </aside>
          )}
        </div>
      </div>
    </>
  );
}
