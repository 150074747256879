import imageCompression from "browser-image-compression";
import {
  Globe,
  Link,
  X,
  UserCircle,
  Image,
  Shield,
  FileText,
  Upload,
  LoaderCircle,
} from "lucide-react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { FrameSettingsValues } from "./FrameEditorPage";
import { FieldContainer, InputField, SwitchField } from "../SharedForm";
import { DynamicListField } from "../SharedFormList";

const ImageUploadField = () => {
  const form = useFormContext<FrameSettingsValues>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.[0];
    if (!file) return;

    const validTypes = [
      "image/png",
      "image/jpeg",
      "image/svg+xml",
      "image/webp",
    ];
    if (!validTypes.includes(file.type)) {
      setError("Please upload a PNG, JPEG, SVG or WebP file");
      return;
    }

    const maxSizeBytes = 30 * 1024;
    if (file.size > maxSizeBytes) {
      const options = {
        maxSizeMB: maxSizeBytes / (1024 * 1024),
        maxIteration: 100,
        useWebWorker: true,
      };
      try {
        setLoading(true);
        file = await imageCompression(file, options);
        setError("");
      } catch (err) {
        setError("Error compressing image to 30KB");
        return;
      } finally {
        setLoading(false);
      }
    }

    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        console.log("image string length", base64String.length / 1024, "KB");
        form.setValue("header_image_url", base64String, {
          shouldDirty: true,
        });
        setError("");
      };
      reader.readAsDataURL(file);
    } catch (err) {
      setError("Error processing image");
    }
  };

  const clearImage = () => {
    form.setValue("header_image_url", "", {
      shouldDirty: true,
    });
    setError("");
  };

  const headerImageUrl = form.watch("header_image_url") || "";
  const isBase64Image = headerImageUrl?.startsWith("data:image/");

  return (
    <FormField
      control={form.control}
      name="header_image_url"
      render={({ field }) => (
        <FormItem>
          <FieldContainer
            icon={Image}
            label="Header Image"
            description="Header image URL or PNG, JPEG, SVG or WEBP file (compressed if >30KB)."
          >
            <div className="space-y-4">
              {/* URL Input */}
              {!isBase64Image && (
                <FormControl>
                  <Input
                    placeholder="Enter the header image URL (optional)"
                    {...field}
                    value={field.value ?? ""}
                    className="h-9 rounded-md border border-gray-200 bg-white/50 px-3 py-1 shadow-sm focus:border-primary focus:ring-2 focus:ring-primary"
                  />
                </FormControl>
              )}

              {/* Upload Button & Preview */}
              <div className="flex items-center gap-4">
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() =>
                    (
                      document.getElementById(
                        "image-upload"
                      ) as HTMLInputElement
                    ).click()
                  }
                  className="text-sm"
                >
                  {loading ? (
                    <>
                      <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
                      Processing
                    </>
                  ) : (
                    <>
                      <Upload className="mr-2 h-4 w-4" />
                      Upload Image
                    </>
                  )}
                </Button>

                {isBase64Image && (
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={clearImage}
                    className="text-sm text-red-500 hover:text-red-600"
                  >
                    <X className="mr-2 h-4 w-4" />
                    Remove Image
                  </Button>
                )}
              </div>

              <input
                id="image-upload"
                type="file"
                accept=".png,.jpg,.jpeg,.svg,.webp"
                className="hidden"
                onChange={handleFileChange}
              />

              {/* Error Message */}
              {error && (
                <Alert variant="destructive" className="mt-2">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              {/* Preview */}
              {isBase64Image && (
                <div className="mt-4">
                  <p className="mb-2 text-sm text-gray-500">Preview:</p>
                  <img
                    src={headerImageUrl}
                    alt="Header preview"
                    className="max-h-24 rounded border border-gray-200"
                  />
                </div>
              )}
            </div>
            <FormMessage className="text-xs text-red-500" />
          </FieldContainer>
        </FormItem>
      )}
    />
  );
};

const AllowedOriginsField = () => {
  return (
    <DynamicListField<FrameSettingsValues, string>
      name="allowed_origins"
      label="Allowed Origins"
      description="URLs that are allowed to embed this chat interface. Use * for wildcards (e.g. https://*.example.com)."
      icon={<Globe className="h-4 w-4 text-primary" />}
      defaultValue=""
      addButtonLabel="Add URL"
      renderField={(field) => (
        <Input
          {...field}
          placeholder="https://example.com"
          className="h-9 rounded-md border border-gray-200 bg-white/50 px-3 py-1 shadow-sm focus:border-primary focus:ring-2 focus:ring-primary"
        />
      )}
    />
  );
};

export const GeneralSettings = () => {
  const form = useFormContext<FrameSettingsValues>();

  return (
    <Card className="w-full rounded-xl border border-gray-100 bg-white/80 shadow-none">
      <CardHeader className="border-b border-gray-100 pb-4">
        <div className="flex items-center gap-2">
          <FileText className="h-5 w-5 text-primary" />
          <CardTitle className="text-lg font-semibold text-gray-800">
            General Settings
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="space-y-8 p-6">
        <div className="space-y-6">
          <FormField
            control={form.control}
            name="assistant_name"
            render={({ field }) => (
              <FormItem>
                <FieldContainer
                  icon={UserCircle}
                  label="Assistant Name"
                  description="The assistant's name in conversations."
                >
                  <FormControl>
                    <Input
                      placeholder="Enter the assistant name"
                      {...field}
                      className="h-9 rounded-md border border-gray-200 bg-white/50 px-3 py-1 shadow-sm focus:border-primary focus:ring-2 focus:ring-primary"
                    />
                  </FormControl>
                  <FormMessage className="text-xs text-red-500" />
                </FieldContainer>
              </FormItem>
            )}
          />

          <ImageUploadField />

          <div className="space-y-4">
            <SwitchField<FrameSettingsValues>
              name="show_sources"
              label="Show Sources"
              description="Display source references in chat responses"
              icon={Link}
            />

            <SwitchField<FrameSettingsValues>
              name="show_popup"
              label="Preview Suggestions"
              description="Display a popup, previewing chatbot suggestions after 5 seconds"
              icon={Shield}
            />
          </div>

          <AllowedOriginsField />

          <div className="space-y-4 rounded-lg bg-gray-50/50 p-4">
            <InputField
              form={form}
              name="terms_of_service_url"
              icon={FileText}
              label="Terms of Service URL"
              description="Link to your terms of service."
              placeholder="Enter terms of service URL (optional)"
            />

            <InputField
              form={form}
              name="privacy_policy_url"
              icon={FileText}
              label="Privacy Policy URL"
              description="Link to your privacy policy."
              placeholder="Enter privacy policy URL (optional)"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
