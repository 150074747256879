import { LibraryBig, LifeBuoy, MessageCircle, Route, Zap } from "lucide-react";
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { Link } from "react-router-dom";
import { Feature } from "@/features";
import { useToken } from "@/hooks";

export function NavSecondary(
  props: React.ComponentPropsWithoutRef<typeof SidebarGroup>
) {
  const { setOpenMobile } = useSidebar();
  const token = useToken();
  const adminDashboardUrl = `${import.meta.env.VITE_DASHBOARD_URL}?embed=true&token=${token}`;

  return (
    <SidebarGroup {...props} className="pt-0">
      <SidebarMenu>
        <Feature flag="sales">
          <SidebarMenuItem>
            <SidebarMenuButton asChild onClick={() => setOpenMobile(false)}>
              <Link to="/sales">
                <Route className="size-4" />
                <span>Sales Pipeline</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </Feature>

        <Feature flag="admin-dashboard">
          <SidebarMenuItem>
            <SidebarMenuButton asChild onClick={() => setOpenMobile(false)}>
              <Link to={adminDashboardUrl} target="_blank">
                <Zap className="size-4" />
                <span>Admin</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </Feature>
        <SidebarMenuItem>
          <SidebarMenuButton
            onClick={() => {
              setOpenMobile(false);
              if (window.$crisp.is("chat:visible")) {
                window.$crisp.push(["do", "chat:hide"]);
              } else {
                window.$crisp.push(["do", "chat:show"]);
                window.$crisp.push(["do", "chat:open"]);
              }
            }}
          >
            <LifeBuoy className="size-4" />
            <span>Help</span>
          </SidebarMenuButton>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <SidebarMenuButton
            onClick={() => {
              setOpenMobile(false);
              if (window.$crisp.is("chat:visible")) {
                window.$crisp.push(["do", "chat:hide"]);
              } else {
                window.$crisp.push(["do", "chat:show"]);
                window.$crisp.push(["do", "chat:open"]);
              }
            }}
          >
            <MessageCircle className="size-4" />
            <span>Feedback</span>
          </SidebarMenuButton>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <SidebarMenuButton asChild>
            <Link to="https://botbrains-docs.notion.site/" target="_blank">
              <LibraryBig className="size-4" />
              <span>Documentation</span>
            </Link>
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarGroup>
  );
}

export default NavSecondary;
