import { useMutation, useQuery } from "@tanstack/react-query";
import {
  AlertCircle,
  ArrowUpRight,
  Lightbulb,
  Plus,
  Wand2,
  Check,
  X,
  Languages,
  MessageSquare,
  MessagesSquare,
  Loader2,
} from "lucide-react";
import React from "react";
import {
  useFormContext,
  useFieldArray,
  type FieldArrayWithId,
} from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  generateSuggestionsOptions,
  translateLocalizationMutation,
} from "@/client/@tanstack/react-query.gen";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Textarea } from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { toast } from "sonner";
import { IETF_BCP_47, type LanguageInfo } from "@/ietf-bcp-47";
import { cn, stringErrorMessage } from "@/lib/utils";

import { useFrameEditor } from "./FrameEditorContext";
import { type FrameSettingsValues } from "./FrameEditorPage";
import { FieldContainer } from "../SharedForm";
import { LanguageDisplay } from "./LanguageDisplay";
import { LanguageSelectionDialog } from "./LanguageSelectionDialog";
import { SortableListField } from "../SharedFormList";

interface LanguageSelectorProps {
  languages: LanguageInfo[];
  fields: FieldArrayWithId<FrameSettingsValues, "locales", "id">[];
  selectedLocaleIndex: number;
  onSelectLocale: (index: number) => void;
  onAutoTranslate: () => void;
  onShowNewLocale: () => void;
  isTranslating: boolean;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  languages,
  fields,
  selectedLocaleIndex,
  onSelectLocale,
  onAutoTranslate,
  onShowNewLocale,
  isTranslating,
}) => {
  const form = useFormContext<FrameSettingsValues>();
  const [open, setOpen] = React.useState(false);
  const selectedField = fields[selectedLocaleIndex];
  const selectedLanguage = languages.find(
    (lang) => lang.code === selectedField?.locale
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          aria-label="Select a language"
          className="w-40 justify-between"
        >
          {selectedLanguage ? (
            <LanguageDisplay
              language={selectedLanguage}
              showNative={false}
              showCountryName={false}
            />
          ) : (
            <span>Select language...</span>
          )}
          <Plus className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search language..." />
          <CommandList>
            <CommandEmpty>No language found.</CommandEmpty>
            <CommandGroup heading="Active Languages">
              {fields.map((field, index) => {
                const language = languages.find(
                  (lang) => lang.code === field.locale
                );
                if (!language) return null;
                return (
                  <CommandItem
                    key={field.id}
                    onSelect={() => {
                      onSelectLocale(index);
                      setOpen(false);
                    }}
                    className="text-sm"
                    value={`${language.localizedName} ${language.nativeName}`}
                  >
                    <LanguageDisplay
                      language={language}
                      showNative={false}
                      showCountryName={false}
                    />
                    <Check
                      className={cn(
                        "ml-auto h-4 w-4",
                        selectedLocaleIndex === index
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
          <CommandSeparator />
          <CommandGroup>
            <CommandItem
              onSelect={onAutoTranslate}
              disabled={isTranslating || !form.formState.isValid}
              className={cn(isTranslating && "cursor-not-allowed opacity-50")}
            >
              {isTranslating ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Translating...
                </>
              ) : (
                <>
                  <Wand2 className="mr-2 h-4 w-4" />
                  Translate with AI
                </>
              )}
            </CommandItem>
            <CommandItem onSelect={onShowNewLocale}>
              <Plus className="mr-2 h-4 w-4" />
              Add Language
            </CommandItem>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

interface SuggestionsListProps {
  language: LanguageInfo;
  locIndex: number;
}

export function SuggestionsList({ language, locIndex }: SuggestionsListProps) {
  const { projectId, frameId } = useParams();
  if (!projectId || !frameId) throw new Error("Missing projectId or frameId");

  const form = useFormContext<FrameSettingsValues>();
  const items = form.watch(`locales.${locIndex}.suggestions`);
  const [isGenerating, setIsGenerating] = React.useState(false);

  const append = (suggestions: string[]) => {
    const newValue = [...items, ...suggestions];
    form.setValue(`locales.${locIndex}.suggestions`, newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const { refetch } = useQuery({
    ...generateSuggestionsOptions({
      path: {
        project_id: Number(projectId),
        frame_id: frameId,
      },
      query: {
        locale: language.code,
      },
    }),
    enabled: false,
  });

  const generateSuggestions = async () => {
    setIsGenerating(true);
    const result = await refetch();
    if (result.data?.suggestions) {
      append(result.data.suggestions);
      setIsGenerating(false);
    }
  };

  return (
    <SortableListField<FrameSettingsValues, string>
      name={`locales.${locIndex}.suggestions`}
      label="Suggestions"
      description="Suggested questions for the user to choose from."
      icon={<MessagesSquare className="h-4 w-4 text-primary" />}
      defaultValue=""
      addButtonLabel="Add Suggestion"
      renderField={(field) => (
        <Input
          {...field}
          className="h-9 rounded-md border border-gray-200 bg-white/50 px-3 py-1 shadow-sm focus:border-primary focus:ring-2 focus:ring-primary"
          placeholder={`Suggestion ${parseInt(field.name.split(".").pop() || "0") + 1}`}
        />
      )}
    >
      <Button
        type="button"
        variant="outline"
        size="sm"
        className="w-full"
        disabled={isGenerating}
        onClick={() => void generateSuggestions()}
      >
        {isGenerating ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Generating...
          </>
        ) : (
          <>
            <Wand2 className="mr-2 h-4 w-4 text-primary" />
            Generate Suggestions with AI
          </>
        )}
      </Button>
    </SortableListField>
  );
}
interface LocalizationCardProps {
  field: FieldArrayWithId<FrameSettingsValues, "locales", "id">;
  index: number;
  showRemove: boolean;
  remove: (index: number) => void;
}

const LocalizationCard: React.FC<LocalizationCardProps> = ({
  field,
  index,
  showRemove,
  remove,
}) => {
  const form = useFormContext<FrameSettingsValues>();
  const language = IETF_BCP_47.find((lang) => lang.code === field.locale);

  if (!language) return null;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <LanguageDisplay
            language={language}
            showNative={true}
            showCountryName={false}
          />
        </div>
        {showRemove && (
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => remove(index)}
                  className="h-8 w-8 p-0 transition-colors hover:bg-red-50 hover:text-red-500"
                >
                  <X className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Delete</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>

      <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50/50 p-4">
        <FormField
          key={field.id}
          control={form.control}
          name={`locales.${index}.messages.0.content`}
          render={({ field: messageField }) => (
            <FormItem>
              <FieldContainer
                icon={MessageSquare}
                label="Start Message"
                description="The first message displayed when the assistant is opened."
              >
                <FormControl>
                  <Textarea
                    {...messageField}
                    placeholder="Enter message"
                    className="min-h-[100px] rounded-md border border-gray-200 bg-white/50 px-3 py-2 shadow-sm focus:border-primary focus:ring-2 focus:ring-primary"
                  />
                </FormControl>
                <FormMessage className="text-xs text-red-500" />
              </FieldContainer>
            </FormItem>
          )}
        />
      </div>

      <SuggestionsList language={language} locIndex={index} />
    </div>
  );
};

// major european languages
const TRANSLATE_CODES = [
  "en-US",
  "en-GB",
  "de-DE",
  "fr-FR",
  "it-IT",
  "es-ES",
  "nl-NL",
  "pt-PT",
  "pl-PL",
  "ru-RU",
  "uk-UA",
  "cs-CZ",
  "el-GR",
  "sv-SE",
  "zh-CN",
  "hi-IN",
] as const;

const LocalizationSettings: React.FC = () => {
  const [showNewLocaleDialog, setShowNewLocaleDialog] = React.useState(false);
  const {
    control,
    formState: { isDirty },
  } = useFormContext<FrameSettingsValues>();
  const { fields, append, remove } = useFieldArray({
    name: "locales",
    control,
  });
  const { projectId, frameId } = useParams();

  const { currentLocale, setCurrentLocale } = useFrameEditor();

  const selectedLocaleIndex = React.useMemo(() => {
    return fields.findIndex((field) => field.locale === currentLocale) || 0;
  }, [fields, currentLocale]);

  const handleSelectLocale = (index: number) => {
    const selectedField = fields[index];
    if (selectedField) {
      setCurrentLocale(selectedField.locale);
    }
  };

  const translateMutation = useMutation({
    ...translateLocalizationMutation(),
    onError: (error) => {
      toast.error("Translations failed", {
        description: stringErrorMessage(error),
      });
    },
  });

  const handleAutoTranslate = async () => {
    const targetLocales = TRANSLATE_CODES.filter(
      (code) => !fields.some((field) => field.locale === code)
    );

    if (targetLocales.length === 0) {
      toast("No new languages", {
        description: "All common languages are already added",
      });
      return;
    }

    const response = await translateMutation.mutateAsync({
      body: {
        base: fields[selectedLocaleIndex], // current locale
        locales: targetLocales,
      },
      path: {
        project_id: Number(projectId),
      },
    });

    response.translations.forEach((translation) => {
      append(translation);
    });

    toast("Translations added", {
      description: `Added ${response.translations.length} new translations`,
    });
    handleSelectLocale(fields.length);
  };

  const addNewLocale = (lang: LanguageInfo) => {
    append({
      locale: lang.code,
      messages: [{ role: "assistant", content: "" }],
      suggestions: [],
    });
    setCurrentLocale(lang.code);
    setShowNewLocaleDialog(false);
  };

  return (
    <Card className="w-full rounded-xl border border-gray-100 bg-white/80 shadow-none">
      <CardHeader className="flex flex-row items-center justify-between border-b border-gray-100 pb-4">
        <div className="flex items-center gap-2">
          <Languages className="h-5 w-5 text-primary" />
          <CardTitle className="text-lg font-semibold text-gray-800">
            Messages
          </CardTitle>
        </div>
        <LanguageSelector
          languages={IETF_BCP_47}
          fields={fields}
          selectedLocaleIndex={selectedLocaleIndex}
          onSelectLocale={handleSelectLocale}
          onAutoTranslate={handleAutoTranslate}
          onShowNewLocale={() => setShowNewLocaleDialog(true)}
          isTranslating={translateMutation.isPending}
        />
      </CardHeader>
      <CardContent className="space-y-6 p-6">
        {fields[selectedLocaleIndex] && (
          <LocalizationCard
            field={fields[selectedLocaleIndex]}
            index={selectedLocaleIndex}
            showRemove={fields.length > 1}
            remove={(index) => {
              remove(index);
              handleSelectLocale(Math.max(0, index - 1));
            }}
          />
        )}
        {frameId && (
          <LocalePreview
            isDirty={isDirty}
            frameId={frameId}
            locales={fields.map((f) => f.locale)}
          />
        )}
      </CardContent>
      <LanguageSelectionDialog
        title="Add New Language"
        open={showNewLocaleDialog}
        onOpenChange={setShowNewLocaleDialog}
        languages={IETF_BCP_47.filter(
          (lang) => !fields.some((field) => field.locale === lang.code)
        )}
        selectLocale={addNewLocale}
      />
    </Card>
  );
};

export default LocalizationSettings;

const LocalePreview = ({
  frameId,
  locales,
  isDirty = false,
}: {
  isDirty?: boolean;
  frameId: string;
  locales: string[];
}) => {
  const previewUrl = `/frame-locale-gallery?frameId=${frameId}&locales=${locales.join(".")}`;

  return (
    <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50/50 p-4">
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center gap-2">
            <Lightbulb className="h-4 w-4 text-primary" />
            <span className="text-sm font-medium text-gray-700">
              Preview Locales
            </span>
          </div>
          <p className="text-sm text-gray-500">
            {isDirty ? "After saving, you can p" : "P"}review all configured
            locales.
          </p>
        </div>
        <Button
          disabled={isDirty}
          type="button"
          variant="outline"
          size="sm"
          className={`text-sm ${isDirty ? "cursor-not-allowed" : ""}`}
          asChild
        >
          {!isDirty ? (
            <a
              href={previewUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => isDirty && e.preventDefault()}
            >
              <ArrowUpRight className="mr-2 h-4 w-4" />
              View Preview
            </a>
          ) : (
            <span>
              <AlertCircle className="mr-2 h-4 w-4" />
              Save First
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};
