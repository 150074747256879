import { useEffect, useState } from "react";

import { useCurrentProjectBreadcrumbs } from "@/hooks";

import QueryDateRangePicker from "../QueryDateRangePicker";
import NavigationHeader from "../sidebar/NavigationHeader";
import PageHeader from "../PageHeader";
import {
  nullableDateHandler,
  stringArrayHandler,
  stringHandler,
  useQueryParam,
  useQueryState,
} from "@/hooks/useQueryState";
import { searchMessagesInfiniteOptions } from "@/client/@tanstack/react-query.gen";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AxiosErrorBox } from "../Error";
import { Input } from "../ui/input";

import { AdvancedFilter } from "./MessageFilter";
import SearchHelpDialog from "./SearchHelpDialog";
import ActiveFilters from "./ActiveFilters";
import { useParams } from "react-router-dom";
import { HandoffStatus, MessageRead } from "@/client";
import { MessageCard, MessageCardSkeleton } from "./MessageList";
import Empty from "../Empty";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { Search } from "lucide-react";

const LoadingMessageList = () => {
  const skeletons = Array.from({ length: 6 }, (_, i) => i);
  return (
    <div className="grid grid-cols-1 gap-4 mt-2 sm:grid-cols-2 lg:grid-cols-3">
      {skeletons.map((index) => (
        <MessageCardSkeleton key={index} />
      ))}
    </div>
  );
};

function MessageList({
  messages,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: {
  messages: MessageRead[];
  fetchNextPage: () => Promise<any>;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}) {
  const observerTarget = useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  });

  if (messages.length === 0) {
    return (
      <div className="mt-12">
        <Empty message="No messages found." />
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-4 mt-2 sm:grid-cols-2 lg:grid-cols-3">
        {messages.map((message) => (
          <MessageCard key={message.id} message={message} />
        ))}
      </div>

      <div ref={observerTarget} className="h-4 mt-4">
        {isFetchingNextPage && <LoadingMessageList />}
      </div>
    </>
  );
}

function MessageSearchPage() {
  const { projectId } = useParams();

  const since = useQueryParam("since", null, nullableDateHandler);
  const until = useQueryParam("until", null, nullableDateHandler);
  const [aliases] = useQueryState("alias", [], stringArrayHandler);
  const [fullTextSearch, setFullTextSearch] = useQueryState(
    "query",
    "",
    stringHandler
  );

  const rating = useQueryParam("rating", [], stringArrayHandler);
  const completeness = useQueryParam("completeness", [], stringArrayHandler);
  const sentiment = useQueryParam("sentiment", [], stringArrayHandler);
  const languages = useQueryParam("languages", [], stringArrayHandler);
  const pages = useQueryParam("pages", [], stringArrayHandler);
  const handoff = useQueryParam("handoff", [], stringArrayHandler);

  // Debounce state for fullTextSearch
  const [debouncedFullTextSearch, setDebouncedFullTextSearch] =
    useState(fullTextSearch);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    setIsDebouncing(true);
    const handler = setTimeout(() => {
      setDebouncedFullTextSearch(fullTextSearch);
      setIsDebouncing(false);
    }, 500);

    return () => {
      clearTimeout(handler);
      setIsDebouncing(false);
    };
  }, [fullTextSearch]);

  const {
    data,
    isPending,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    ...searchMessagesInfiniteOptions({
      path: {
        project_id: Number(projectId),
      },
      body: {
        project_id: Number(projectId),
        since: since,
        until: until,
        text: debouncedFullTextSearch,
        aliases: aliases,
        tags: [
          ...completeness.map((value) => ({
            name: "nlp:assistant.completeness",
            value,
          })),
          ...rating.map((value) => ({ name: "chat:rating", value })),
          ...sentiment.map((value) => ({ name: "nlp:user.sentiment", value })),
          ...languages.map((value) => ({ name: "nlp:user.language", value })),
          ...pages.map((value) => ({ name: "chat:website:url", value })),
        ],
        handoff: handoff as HandoffStatus[],
      },
    }),
    getNextPageParam: (lastPage) => lastPage.next_cursor,
    initialPageParam: 0,
    enabled: !isDebouncing,
  });

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Messages" },
  ];

  if (error) return <AxiosErrorBox error={error} />;

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <PageHeader title="Search Messages">
        <AdvancedFilter />
      </PageHeader>
      <main className="mt-2">
        <div className="space flex w-full flex-row flex-wrap justify-between gap-4 sm:flex-nowrap">
          <div className="flex w-full flex-row">
            <div className="relative flex w-full max-w-sm items-center">
              <Input
                type="text"
                placeholder="Search..."
                value={fullTextSearch}
                onChange={(e) => setFullTextSearch(e.target.value)}
                className="pr-8"
              />
              <div className="absolute right-2">
                <Search className="h-4 w-4 text-gray-400" />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <SearchHelpDialog />
            </div>
          </div>

          <div className="w-full sm:w-auto">
            <QueryDateRangePicker forceDateRange={false} />
          </div>
        </div>
        <ActiveFilters />
        <div className="mt-4">
          {isDebouncing || isPending ? (
            <LoadingMessageList />
          ) : (
            <MessageList
              messages={data.pages.flatMap((page) => page.data)}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
        </div>
      </main>
    </>
  );
}

export default MessageSearchPage;
