import { formatDistanceToNowStrict } from "date-fns";
import {
  Mail,
  Check,
  Globe,
  Activity,
  MessagesSquare,
  UserPen,
} from "lucide-react";

import { UserWithConversationsRead } from "@/client/types.gen";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent } from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { findClosestLanguageMatch } from "@/ietf-bcp-47";

import { getInitials } from "./UserCard";
import { Skeleton } from "../ui/skeleton";
import { cn } from "@/lib/utils";
import { useState } from "react";
import UserUpdateDialog from "./UserUpdateDialog";
import { Link, useParams } from "react-router-dom";

export function CompactUserCardSkeleton() {
  return (
    <Card className="w-full hover:bg-muted/20 transition-colors">
      <CardContent className="p-4">
        <div className="flex items-center gap-4">
          {/* Avatar Section with Status */}
          <div className="flex flex-col items-center flex-shrink-0">
            <Skeleton className="h-12 w-12 rounded-full" />
            <div className="flex items-center mt-1 text-xs text-muted-foreground whitespace-nowrap">
              <Activity className="h-2 w-2 mr-0.5" />
              <Skeleton className="h-3 w-16 m-0.5" />
            </div>
          </div>

          {/* Main Info Section */}
          <div className="flex-1 min-w-0 py-1">
            <div className="flex items-center gap-1">
              <Skeleton className="h-4 w-40 m-1" />
              <Skeleton className="h-4 w-20 m-1" />
            </div>

            <div className="flex gap-2">
              <div className="flex items-center text-xs text-muted-foreground mt-1">
                <MessagesSquare className="h-3 w-3 mr-1 flex-shrink-0" />
                <Skeleton className="h-3 w-20 m-0.5" />
              </div>
              <div className="flex items-center text-xs text-muted-foreground mt-1">
                <Globe className="h-3 w-3 mr-1 flex-shrink-0" />
                <Skeleton className="h-3 w-20 m-0.5" />
              </div>
            </div>
            <div className="flex items-center gap-1 text-xs mt-1">
              <Mail className="h-3 w-3 text-muted-foreground flex-shrink-0" />
              <Skeleton className="h-3 w-40 m-0.5" />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default function CompactUserCard({
  user,
}: {
  user: UserWithConversationsRead;
}) {
  const { projectId } = useParams();
  const name = user.name || "Anonymous User";

  const processedLocale = user.locales?.[0]
    ? findClosestLanguageMatch(user.locales[0])
    : null;

  const [open, setOpen] = useState(false);

  return (
    <>
      <Link key={user.id} to={`/${projectId}/conversations?user=${user.id}`}>
        <Card className="relative group w-full h-full hover:bg-muted/20 transition-colors">
          <button
            className={cn(
              open ? "visible" : "invisible group-hover:visible",
              "absolute -right-2 -top-2 text-white px-3 py-1.5 bg-primary hover:bg-primary/90 rounded-full text-xs font-medium flex items-center gap-1.5 shadow-sm transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            )}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(true);
            }}
          >
            <UserPen className="h-3.5 w-3.5" />
            Edit User
          </button>
          <CardContent className="p-4 overflow-hidden">
            <div className="flex items-center gap-4">
              {/* Avatar Section with Status */}
              <div className="flex flex-col items-center flex-shrink-0 w-20">
                <Avatar className="h-10 w-10 ring-2 ring-primary/40">
                  <AvatarFallback className="bg-gradient-to-br from-primary/20 to-primary/10 text-primary">
                    {getInitials(user.first_name, user.last_name)}
                  </AvatarFallback>
                </Avatar>
                <div className="flex items-center mt-1 text-xs text-muted-foreground whitespace-nowrap">
                  <Activity className="h-2 w-2 mr-0.5" />
                  {formatDistanceToNowStrict(new Date(user.last_seen_at), {
                    addSuffix: true,
                  })}
                </div>
              </div>

              {/* Main Info Section */}
              <div className="flex-1 min-w-0 py-1">
                <div className="flex items-center gap-2">
                  <h3 className="font-medium truncate">{name}</h3>
                  {processedLocale && (
                    <Badge variant="secondary" className="text-xs truncate">
                      {processedLocale.emoji}{" "}
                      {processedLocale.shortLocalizedName || user.locales[0]}
                    </Badge>
                  )}
                </div>
                <div className="flex gap-2">
                  <div className="flex items-center text-xs truncate text-muted-foreground mt-1">
                    <MessagesSquare className="h-3 w-3 mr-1 flex-shrink-0" />
                    <span>{user.conversations.length} Conversations</span>
                  </div>
                  <div className="flex items-center text-xs text-muted-foreground mt-1">
                    <Globe className="h-3 w-3 mr-1 flex-shrink-0" />
                    <span>{user.timezone}</span>
                  </div>
                </div>

                {user.email && (
                  <div className="flex items-center gap-1 text-xs mt-1">
                    <Mail className="h-3 w-3 text-muted-foreground flex-shrink-0" />
                    <span className="truncate">{user.email}</span>
                    {user.email_signed && (
                      <TooltipProvider>
                        <Tooltip delayDuration={0}>
                          <TooltipTrigger>
                            <div className="p-0.5 bg-blue-500 rounded-full">
                              <Check className="h-2 w-2 text-white" />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>Verified Email</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </Link>
      <UserUpdateDialog user={user} open={open} onOpenChange={setOpen} />
    </>
  );
}
