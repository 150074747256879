import { useCurrentProjectBreadcrumbs } from "../../hooks";

import { useParams } from "react-router-dom";

import NavigationHeader from "../sidebar/NavigationHeader";
import { useInfiniteQuery } from "@tanstack/react-query";
import { listProjectUsersInfiniteOptions } from "@/client/@tanstack/react-query.gen";
import PageHeader from "../PageHeader";
import { AxiosErrorBox } from "../Error";
import CompactUserCard, { CompactUserCardSkeleton } from "./CompactUserCard";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";
import { useEffect, useState } from "react";
import UserCard, { UserCardSkeleton } from "./UserCard";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import {
  booleanHandler,
  stringHandler,
  useQueryState,
} from "@/hooks/useQueryState";
import { Input } from "../ui/input";
import SearchHelpDialog from "../messages/SearchHelpDialog";
import { Search, User, UserCog } from "lucide-react";
import { Button } from "../ui/button";

function UserListSkeleton({ showDetails }: { showDetails: boolean }) {
  return (
    <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 lg:grid-cols-3">
      {[...Array(12)].map((_, i) =>
        showDetails ? (
          <UserCardSkeleton key={i} />
        ) : (
          <CompactUserCardSkeleton key={i} />
        )
      )}
    </div>
  );
}

function UserList({
  showDetails,
  onlyIdentifiable,
  debouncedFullTextSearch,
  isDebouncing,
}: {
  showDetails: boolean;
  onlyIdentifiable: boolean;
  debouncedFullTextSearch: string;
  isDebouncing: boolean;
}) {
  const { projectId, userPoolId } = useParams();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
    error,
  } = useInfiniteQuery({
    ...listProjectUsersInfiniteOptions({
      path: {
        project_id: Number(projectId),
        pool_id: userPoolId as string,
      },
      query: {
        full_text_search: debouncedFullTextSearch,
        only_identifiable: onlyIdentifiable,
      },
    }),
    getNextPageParam: (lastPage) => lastPage.next_cursor,
    initialPageParam: 0,
    enabled: !isDebouncing,
  });

  const observerTarget = useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  });

  if (error) return <AxiosErrorBox error={error} />;
  if (isPending || isDebouncing)
    return <UserListSkeleton showDetails={showDetails} />;

  const users = data.pages.flatMap((page) => page.data);

  return (
    <>
      <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 auto-rows-max">
        {users.map((user) =>
          showDetails ? (
            <UserCard key={user.id} user={user} devices={[]} />
          ) : (
            <CompactUserCard key={user.id} user={user} />
          )
        )}
      </div>

      <div ref={observerTarget} className="h-4 mt-4 flex justify-center">
        {/* HACK(memben): observer target does sometimes not fire - allow manually firing
                          TODO: investigate why observer target does not always fire
        */}
        {hasNextPage && !isFetchingNextPage && (
          <Button onClick={() => fetchNextPage()} type="button">
            Load more
          </Button>
        )}
        {isFetchingNextPage && (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {[...Array(3)].map((_, i) =>
              showDetails ? (
                <UserCardSkeleton key={i} />
              ) : (
                <CompactUserCardSkeleton key={i} />
              )
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default function UserListPage() {
  const breadcrumbs = [...useCurrentProjectBreadcrumbs(), { label: "Users" }];
  const [showDetails, setShowDetails] = useQueryState(
    "view-details",
    false,
    booleanHandler
  );
  const [onlyIdentifiable, setOnlyIdentifiable] = useQueryState(
    "identifiable",
    false,
    booleanHandler
  );

  const [fullTextSearch, setFullTextSearch] = useQueryState(
    "query",
    "",
    stringHandler
  );

  // Debounce state for fullTextSearch
  const [debouncedFullTextSearch, setDebouncedFullTextSearch] =
    useState(fullTextSearch);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    setIsDebouncing(true);
    const handler = setTimeout(() => {
      setDebouncedFullTextSearch(fullTextSearch);
      setIsDebouncing(false);
    }, 500);

    return () => {
      clearTimeout(handler);
      setIsDebouncing(false);
    };
  }, [fullTextSearch]);

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <PageHeader title="Users">
        <Button
          variant="outline"
          className="h-8 px-3 flex items-center gap-2 w-48"
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? (
            <div className="flex items-center gap-2">
              <UserCog className="h-4 w-4" />

              <span>Compact</span>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <User className="h-4 w-4" />

              <span>Detailed</span>
            </div>
          )}
        </Button>
      </PageHeader>
      <div className="flex justify-between items-center my-2">
        <div className="flex max-w-96 flex-grow flex-row">
          <div className="relative flex  w-full items-center">
            <Input
              type="text"
              placeholder="Search by name, email, phone number..."
              value={fullTextSearch}
              onChange={(e) => setFullTextSearch(e.target.value)}
              className="pr-8 w-full"
            />
            <div className="absolute right-2">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <SearchHelpDialog />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Switch
            checked={onlyIdentifiable}
            onCheckedChange={setOnlyIdentifiable}
          />
          <Label>Only Identifiable</Label>
        </div>
      </div>
      <UserList
        showDetails={showDetails}
        onlyIdentifiable={onlyIdentifiable}
        debouncedFullTextSearch={debouncedFullTextSearch}
        isDebouncing={isDebouncing}
      />
    </>
  );
}
