import { useState, useCallback, useEffect } from "react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import Papa from "papaparse";
import { Download, ChevronDown, ChevronUp, Loader2 } from "lucide-react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getTableContentOptions } from "@/client/@tanstack/react-query.gen";
import TableActions from "./SearchTableActions";
import SearchDialog from "./SearchDialog";
import { SearchTableConfigInput } from "@/client";
import { Feature } from "@/features";
import { useCurrentProjectBreadcrumbs } from "@/hooks";
import PageHeader from "../PageHeader";
import NavigationHeader from "../sidebar/NavigationHeader";
import { Button } from "../ui/button";

// Constants
const TIMEZONES = [
  "UTC",
  "America/New_York",
  "America/Los_Angeles",
  "Europe/London",
  "Europe/Berlin",
  "Asia/Tokyo",
];

const NUMBER_FORMATS = {
  US: { decimal: ".", thousand: "," },
  DE: { decimal: ",", thousand: "." },
};

const DATA_TYPES = [
  "string",
  "number",
  "date",
  "datetime",
  "boolean",
  "json",
] as const;
type DataType = (typeof DATA_TYPES)[number];

const SEARCH_FIELD_TYPES = [
  "none",
  "text",
  "select",
  "multiselect",
  "range",
  "between",
  "toggle",
] as const;
type SearchFieldType = (typeof SEARCH_FIELD_TYPES)[number];

const VALID_SEARCH_TYPES: Record<DataType, SearchFieldType[]> = {
  string: ["none", "text", "select", "multiselect"],
  number: ["none", "range"],
  date: ["none", "between"],
  datetime: ["none", "between"],
  boolean: ["none", "toggle"],
  json: ["none"],
};

function cleanColumnName(name: string) {
  // must be alphanumeric, underscores
  return name.replace(/[^a-zA-Z0-9_]/g, "");
}

type ExportFormat = "csv" | "jsonl" | "json";
// Utility Components
const ExportButtons = ({
  onExport,
}: {
  onExport: (format: ExportFormat) => void;
}) => (
  <div className="flex gap-2">
    {(["csv", "jsonl", "json"] as ExportFormat[]).map((format) => (
      <Button
        type="button"
        key={format}
        onClick={() => onExport(format)}
        className={`flex items-center gap-2 px-3 py-2 text-white rounded hover:bg-${format === "csv" ? "blue" : format === "jsonl" ? "green" : "purple"}-600 bg-${format === "csv" ? "blue" : format === "jsonl" ? "green" : "purple"}-500`}
      >
        <Download size={16} /> Export {format.toUpperCase()}
      </Button>
    ))}
  </div>
);

// Global Settings Component
const GlobalSettings = ({
  timezone,
  numberFormat,
  onSettingsChange,
  onVerify,
}: {
  timezone: string;
  numberFormat: string;
  onSettingsChange: (setting: string, value: string) => void;
  onVerify: () => void;
}) => (
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
    <div>
      <label className="block text-sm font-medium mb-2">Timezone</label>
      <select
        value={timezone}
        onChange={(e) => onSettingsChange("timezone", e.target.value)}
        className="block w-full text-sm border rounded p-2"
      >
        {TIMEZONES.map((tz) => (
          <option key={tz} value={tz}>
            {tz}
          </option>
        ))}
      </select>
    </div>
    <div>
      <label className="block text-sm font-medium mb-2">Number Format</label>
      <select
        value={numberFormat}
        onChange={(e) => onSettingsChange("numberFormat", e.target.value)}
        className="block w-full text-sm border rounded p-2"
      >
        <option value="US">US (1,234.56)</option>
        <option value="DE">DE (1.234,56)</option>
      </select>
    </div>
    <div className="flex items-end">
      <button
        onClick={onVerify}
        className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
      >
        Verify Data
      </button>
    </div>
  </div>
);

// Pagination Component
const Pagination = ({
  currentPage,
  totalPages,
  pageSize,
  onPageChange,
  onPageSizeChange,
}: {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
}) => (
  <div className="mt-4 flex items-center justify-between">
    <div className="flex items-center gap-2">
      <span className="text-sm text-gray-600">Rows per page:</span>
      <select
        value={pageSize}
        onChange={(e) => onPageSizeChange(Number(e.target.value))}
        className="border rounded p-1 text-sm"
      >
        {[5, 10, 20, 50].map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
    </div>
    <div className="flex items-center gap-4">
      <span className="text-sm text-gray-600">
        Page {currentPage + 1} of {totalPages}
      </span>
      <div className="flex gap-2">
        <button
          onClick={() => onPageChange(Math.max(0, currentPage - 1))}
          disabled={currentPage === 0}
          className="p-1 rounded border disabled:opacity-50"
        >
          <ChevronUp size={16} />
        </button>
        <button
          onClick={() =>
            onPageChange(Math.min(totalPages - 1, currentPage + 1))
          }
          disabled={currentPage === totalPages - 1}
          className="p-1 rounded border disabled:opacity-50"
        >
          <ChevronDown size={16} />
        </button>
      </div>
    </div>
  </div>
);
const ColumnHeader = ({
  column,
  config,
  onUpdate,
}: {
  column: string;
  config: {
    name: string;
    data_type: DataType;
    search_type: SearchFieldType;
  };
  onUpdate: (
    column: string,
    field: "name" | "data_type" | "search_type",
    value: any
  ) => void;
}) => (
  <th className="px-4 py-2 border">
    <div className="space-y-2">
      {/* Column Original Name */}
      <div className="text-left text-sm font-medium">{column}</div>

      {/* Column Name Input */}
      <input
        type="text"
        value={config?.name || ""}
        onChange={(e) => onUpdate(column, "name" as const, e.target.value)}
        className="w-full p-1 text-sm border rounded"
        placeholder="Column name"
      />

      {/* Data Type Select */}
      <select
        value={config?.data_type || "string"}
        onChange={(e) => onUpdate(column, "data_type" as const, e.target.value)}
        className="w-full p-1 text-sm border rounded"
      >
        {DATA_TYPES.map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>

      {/* Index Type Select */}
      <select
        value={config?.search_type || "none"}
        onChange={(e) =>
          onUpdate(column, "search_type" as const, e.target.value)
        }
        className="w-full p-1 text-sm border rounded"
      >
        {VALID_SEARCH_TYPES[config?.data_type || "string"].map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
    </div>
  </th>
);

// Data Table Component
const DataTable = ({
  columns,
  data,
  columnConfig,
  onColumnConfigUpdate,
  startIndex,
  endIndex,
}: {
  columns: string[];
  data: any[];
  columnConfig: Record<
    string,
    {
      name: string;
      data_type: DataType;
      search_type: SearchFieldType;
    }
  >;
  onColumnConfigUpdate: (
    column: string,
    field: "name" | "data_type" | "search_type",
    value: string
  ) => void;
  startIndex: number;
  endIndex: number;
}) => (
  <div className="overflow-x-scroll">
    <table className="min-w-full border">
      <thead>
        <tr className="bg-gray-50">
          {columns.map((column) => (
            <ColumnHeader
              key={column}
              column={column}
              config={columnConfig[column]}
              onUpdate={onColumnConfigUpdate}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {data.slice(startIndex, endIndex).map((row, index) => (
          <tr key={index} className="border-t">
            {columns.map((column) => (
              <td key={column} className="px-4 py-2 border text-sm">
                {["string", "number"].includes(typeof row[column])
                  ? row[column] || ""
                  : JSON.stringify(row[column] || "")}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

// Main Component
const DataMetadataManager = () => {
  const { projectId, tableId } = useParams();
  const [data, setData] = useState<any>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [columnConfig, setColumnConfig] = useState<
    Record<
      string,
      {
        name: string;
        data_type: DataType;
        search_type: SearchFieldType;
      }
    >
  >({});
  const [error, setError] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [timezone, setTimezone] = useState<string>("UTC");
  const [numberFormat, setNumberFormat] = useState<string>("US");
  const [verificationResult, setVerificationResult] = useState<{
    status: "success" | "error";
    messages: string[];
  } | null>(null);

  // Fetch existing table data if tableId exists
  const {
    data: tableData,
    isLoading,
    isSuccess,
  } = useQuery({
    ...getTableContentOptions({
      path: {
        project_id: Number(projectId),
        table_id: tableId!,
      },
    }),
    enabled: !!tableId,
  });
  console.log("Table data loaded", tableData);

  const verifyData = useCallback(() => {
    const messages = [];
    let hasErrors = false;

    // Helper function to validate dates
    const isValidDate = (dateStr: string) => {
      const date = new Date(dateStr);
      return date instanceof Date;
    };

    // Helper function to validate numbers based on current number format
    const isValidNumber = (value: unknown): boolean => {
      if (typeof value === "number") return !isNaN(value) && isFinite(value);
      if (typeof value !== "string") return false;

      const format =
        NUMBER_FORMATS[numberFormat as keyof typeof NUMBER_FORMATS];
      if (!format) return false;

      const normalizedValue = value
        .replace(format.thousand, "")
        .replace(format.decimal, ".");

      const parsed = parseFloat(normalizedValue);
      return !isNaN(parsed) && isFinite(parsed);
    };

    // Helper function to validate JSON strings
    const isValidJSON = (str: any) => {
      if (typeof str === "object") return true;
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    };

    // Validate each row
    data.forEach((row: any, rowIndex: number) => {
      Object.entries(columnConfig).forEach(([column, config]) => {
        const value = row[column];

        // Skip validation for null/empty values
        if (value === null || value === undefined || value === "") {
          return;
        }

        switch (config.data_type) {
          case "number":
            if (!isValidNumber(value)) {
              messages.push(
                `Row ${rowIndex + 1}, Column "${column}": Invalid number format - "${value}"`
              );
              hasErrors = true;
            }
            break;

          case "date":
          case "datetime":
            if (!isValidDate(value)) {
              messages.push(
                `Row ${rowIndex + 1}, Column "${column}": Invalid date format - "${value}"`
              );
              hasErrors = true;
            }
            break;

          case "boolean":
            const validBooleans = ["true", "false", "1", "0", true, false];
            if (
              !validBooleans.includes(value) &&
              !validBooleans.includes(String(value).toLowerCase())
            ) {
              messages.push(
                `Row ${rowIndex + 1}, Column "${column}": Invalid boolean value - "${value}"`
              );
              hasErrors = true;
            }
            break;

          case "json":
            if (!isValidJSON(value)) {
              messages.push(
                `Row ${rowIndex + 1}, Column "${column}": Invalid JSON format - "${value}"`
              );
              hasErrors = true;
            }
            break;
        }
      });
    });

    // Validate index configurations
    Object.entries(columnConfig).forEach(([column, config]) => {
      // Validate that index types are compatible with data types
      const validIndexTypes = VALID_SEARCH_TYPES[config.data_type];
      if (!validIndexTypes.includes(config.search_type)) {
        messages.push(
          `Configuration Error: Invalid index type "${config.search_type}" for column "${column}" with data type "${config.data_type}"`
        );
        hasErrors = true;
      }
    });

    // Check for duplicate column names
    const columnNames = Object.values(columnConfig).map(
      (config) => config.name
    );
    const duplicateNames = columnNames.filter(
      (name, index) => columnNames.indexOf(name) !== index
    );
    if (duplicateNames.length > 0) {
      messages.push(
        `Configuration Error: Duplicate column names found - ${duplicateNames.join(", ")}`
      );
      hasErrors = true;
    }

    if (messages.length === 0) {
      messages.push("All data validated successfully!");
    }

    setVerificationResult({
      status: hasErrors ? "error" : "success",
      messages,
    });
  }, [data, columnConfig, numberFormat]);

  const handleFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file === undefined) return;

      setError("");
      const fileExtension = file.name.split(".").pop()!.toLowerCase();

      try {
        if (fileExtension === "csv") {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              if (results.errors.length > 0) {
                setError(
                  `CSV parsing errors:\n${results.errors
                    .map(
                      (err) => `Row ${err.row}: ${err.message} (${err.code})`
                    )
                    .join("\n")}`
                );
                return;
              }
              // We ignore columns named with whitespace.
              const fields =
                results.meta.fields?.filter((field) => field.trim() > "") || [];
              const data = results.data.map((row: any) => {
                const newRow: Record<string, any> = {};
                fields.forEach((key, _) => {
                  newRow[key] = row[key];
                });
                return newRow;
              });
              setData(data);
              setColumns(fields);
              // Initialize column configuration
              const initialConfig: Record<
                string,
                {
                  name: string;
                  data_type: DataType;
                  search_type: SearchFieldType;
                }
              > = {};
              fields.forEach((column) => {
                initialConfig[column] = {
                  name: cleanColumnName(column),
                  search_type: "none", // Default index type
                  data_type: "string", // Default data type
                };
              });
              setColumnConfig(initialConfig);
            },
            error: (error) => {
              setError(`Error parsing CSV: ${error.message}`);
            },
          });
        } else if (fileExtension === "jsonl") {
          const text = await file.text();
          const lines = text.trim().split("\n");
          try {
            const parsedData = lines.map((line: string, index: number) => {
              try {
                return JSON.parse(line);
              } catch (e: any) {
                throw new Error(
                  `Invalid JSON on line ${index + 1}: ${e.message}`
                );
              }
            });

            if (parsedData.length > 0) {
              const columnSet = new Set<string>();
              parsedData.forEach((row: Record<string, any>) => {
                Object.keys(row).forEach((key) => columnSet.add(key));
              });
              const columnList = Array.from(columnSet);
              setData(parsedData);
              setColumns(columnList);

              const initialConfig: Record<
                string,
                {
                  name: string;
                  data_type: DataType;
                  search_type: SearchFieldType;
                }
              > = {};
              const firstRow = parsedData[0];
              columnList.forEach((column) => {
                const _type = typeof firstRow[column];
                const typeMapping: Record<string, DataType> = {
                  bigint: "number",
                  symbol: "string",
                  string: "string",
                  number: "number",
                  boolean: "boolean",
                  object: "json",
                  undefined: "json", // cannot infer type
                };
                initialConfig[column] = {
                  name: cleanColumnName(column),
                  search_type: "none",
                  data_type: typeMapping[_type],
                };
              });
              setColumnConfig(initialConfig);
            }
          } catch (e: any) {
            setError(e.message);
          }
        } else if (fileExtension === "json") {
          const text = await file.text();
          try {
            const json: SearchTableConfigInput = JSON.parse(text);

            if (!json.objects || !Array.isArray(json.objects)) {
              throw new Error(
                'JSON must have an "objects" key containing an array'
              );
            }

            setData(json.objects);

            // If fields are provided, use them for configuration
            if (json.fields && Array.isArray(json.fields)) {
              const newConfig: any = {};
              const columnSet = new Set<string>();

              // Validate fields schema
              json.fields.forEach((field) => {
                if (!field.name || !field.data_type || !field.search_type) {
                  throw new Error(
                    "Each field must have name, data_type, and search_type"
                  );
                }

                if (!DATA_TYPES.includes(field.data_type)) {
                  throw new Error(
                    `Invalid data_type "${field.data_type}" for field "${field.name}"`
                  );
                }

                if (
                  !VALID_SEARCH_TYPES[field.data_type].includes(
                    field.search_type
                  )
                ) {
                  throw new Error(
                    `Invalid search_type "${field.search_type}" for data_type "${field.data_type}" in field "${field.name}"`
                  );
                }

                newConfig[field.name] = field;
                columnSet.add(field.name);
              });

              // Add any missing columns from the data
              json.objects.forEach((obj) => {
                Object.keys(obj).forEach((key) => {
                  if (!columnSet.has(key)) {
                    columnSet.add(key);
                    newConfig[key] = {
                      name: cleanColumnName(key),
                      search_type: "none",
                      data_type: "string",
                      transform: "",
                    };
                  }
                });
              });

              setColumns(Array.from(columnSet));
              setColumnConfig(newConfig);
            } else {
              // No fields provided, infer from data
              const columnSet = new Set<string>();
              json.objects.forEach((obj: any) => {
                Object.keys(obj).forEach((key) => columnSet.add(key));
              });

              const columnList = Array.from(columnSet);
              setColumns(columnList);

              const initialConfig: Record<
                string,
                {
                  name: string;
                  data_type: DataType;
                  search_type: SearchFieldType;
                }
              > = {};
              columnList.forEach((column) => {
                initialConfig[column] = {
                  name: cleanColumnName(column),
                  search_type: "none",
                  data_type: "string",
                };
              });
              setColumnConfig(initialConfig);
            }
          } catch (e) {
            if (e instanceof Error)
              setError(`Error parsing JSON: ${e.message}`);
          }
        } else {
          setError(
            "Unsupported file format. Please upload a CSV, JSONL, or JSON file."
          );
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error("Caught an error:", error.message);
          setError(`Error processing file: ${error.message}`);
        }
      }
    },
    []
  );

  const updateColumnConfig = (
    column: string,
    field: "name" | "data_type" | "search_type",
    value: string
  ): void => {
    setColumnConfig((prev) => {
      const newConfig: Record<
        string,
        {
          name: string;
          data_type: DataType;
          search_type: SearchFieldType;
        }
      > = {
        ...prev,
        [column]: {
          ...prev[column],
          [field]: value,
        },
      };

      // Automatically set search_type to 'none' if data_type is json
      if (field === "data_type" && value === "json") {
        newConfig[column].search_type = "none";
      } else if (field === "data_type") {
        // Type assertion since we know value is a valid DataType here
        newConfig[column].search_type =
          VALID_SEARCH_TYPES[value as DataType][0];
      }

      // Validate search_type against data_type
      if (field === "search_type") {
        const validIndexTypes = VALID_SEARCH_TYPES[newConfig[column].data_type];
        if (!validIndexTypes.includes(value as SearchFieldType)) {
          setError(
            `Invalid search_type "${value}" for data_type "${newConfig[column].data_type}"`
          );
          return prev;
        }
      }

      return newConfig;
    });
  };

  const exportData = (format: "csv" | "jsonl" | "json") => {
    try {
      let exportContent;

      if (format === "csv") {
        exportContent = Papa.unparse(data);
      } else if (format === "jsonl") {
        exportContent = data.map((row: any) => JSON.stringify(row)).join("\n");
      } else if (format === "json") {
        const fields = Object.entries(columnConfig).map(([_, config]) => ({
          name: config.name,
          search_type: config.search_type,
          data_type: config.data_type,
          transform: "none",
        }));

        // Process data according to types
        const processedData = data.map((row: any) => {
          const newRow: {
            [key: string]: any;
          } = {};
          Object.entries(columnConfig).forEach(([column, config]) => {
            let value = row[column];
            if (value === null || value === undefined || value === "") {
              newRow[column] = null;
              return;
            }

            switch (config.data_type) {
              case "number":
                const format =
                  NUMBER_FORMATS[numberFormat as keyof typeof NUMBER_FORMATS];
                const parsedValue = parseFloat(
                  String(value)
                    .replace(format.thousand, "")
                    .replace(format.decimal, ".")
                );
                newRow[column] = isNaN(parsedValue) ? null : parsedValue;
                break;

              case "date":
              case "datetime":
                const date = new Date(value);
                newRow[column] = isNaN(date.getTime())
                  ? null
                  : date.toISOString();
                break;

              case "boolean":
                if (typeof value === "boolean") {
                  newRow[column] = value;
                } else {
                  const strValue = String(value).toLowerCase();
                  newRow[column] = strValue === "true" || strValue === "1";
                }
                break;

              case "json":
                try {
                  newRow[column] =
                    typeof value === "string" ? JSON.parse(value) : value;
                } catch (e) {
                  newRow[column] = null;
                }
                break;

              default:
                newRow[column] = String(value);
            }
          });
          return newRow;
        });

        exportContent = JSON.stringify(
          {
            objects: processedData,
            fields,
            metadata: {
              timezone,
              numberFormat,
            },
          },
          null,
          2
        );
      }

      const blob = new Blob([exportContent], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `export.${format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      if (err instanceof Error)
        setError(`Error exporting data: ${err.message}`);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setData(tableData.config.objects);
      setColumnConfig(
        tableData.config.fields.reduce(
          (acc, field) => {
            acc[field.name] = {
              name: field.name,
              data_type: field.data_type || "string",
              search_type: field.search_type || "none",
            };
            return acc;
          },
          {} as Record<
            string,
            { name: string; data_type: DataType; search_type: SearchFieldType }
          >
        )
      );
      setColumns(tableData.config.fields.map((f) => f.name));
    }
  }, [isSuccess, tableData, setData, setColumnConfig, setColumns]);
  const getTableConfig = useCallback(() => {
    return {
      name: tableData?.config?.name ?? "New Table",
      fields: Object.entries(columnConfig).map(([column, config]) => ({
        name: config.name,
        data_type: config.data_type,
        search_type: config.search_type,
        accessor: column,
      })),
      objects: data,
    };
  }, [columnConfig, data, tableId, tableData]);

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const totalPages = Math.ceil(data.length / pageSize);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }
  return (
    <div className="p-4">
      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-2">
            <div>
              <label className="block text-sm font-medium mb-2">
                Upload File (CSV, JSONL, or JSON)
              </label>
              <input
                type="file"
                accept=".csv,.jsonl,.json"
                onChange={handleFileUpload}
                className="block w-full text-sm border rounded p-2"
              />
            </div>
            {data.length > 0 && <ExportButtons onExport={exportData} />}
          </div>
        </div>
        <div className="flex gap-2 justify-between items-end mb-4">
          <GlobalSettings
            timezone={timezone}
            numberFormat={numberFormat}
            onSettingsChange={(setting, value) => {
              if (setting === "timezone") setTimezone(value);
              if (setting === "numberFormat") setNumberFormat(value);
            }}
            onVerify={verifyData}
          />
          <div className="flex gap-2">
            {tableId && <TableActions getTableConfig={getTableConfig} />}
            {tableId && <SearchDialog />}
          </div>
        </div>

        {verificationResult && (
          <Alert
            variant={
              verificationResult.status === "success"
                ? "default"
                : "destructive"
            }
            className="mb-4"
          >
            <AlertDescription>
              <div className="space-y-1">
                {verificationResult.messages.map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </div>
            </AlertDescription>
          </Alert>
        )}

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription style={{ whiteSpace: "pre-line" }}>
              {error}
            </AlertDescription>
          </Alert>
        )}

        {data.length > 0 && (
          <div className="bg-white rounded-lg shadow p-4">
            <h2 className="text-xl font-semibold mb-4">Data Editor</h2>

            <DataTable
              columns={columns}
              data={data}
              columnConfig={columnConfig}
              onColumnConfigUpdate={updateColumnConfig}
              startIndex={startIndex}
              endIndex={endIndex}
            />

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              pageSize={pageSize}
              onPageChange={setCurrentPage}
              onPageSizeChange={(size) => {
                setPageSize(size);
                setCurrentPage(0);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

function SearchTablePage() {
  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Tables", href: "./.." },
    {
      label: "Edit Table",
    },
  ];
  return (
    <Feature flag="search-tables">
      <NavigationHeader items={breadcrumbs} />
      <PageHeader
        title="Table Explorer"
        subtitle="Save tables after changes to refresh the search interface. CSV/JSONL only store data, JSON files also store the schema data."
      />
      <DataMetadataManager />
    </Feature>
  );
}

export default SearchTablePage;
