import {
  Code,
  Check,
  Copy,
  AlertCircle,
  BeakerIcon,
  Slack,
} from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { FrameRead, SlackIntegration } from "@/client";
import { toast } from "sonner";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import PreviewUrlButton, { PreviewUrlLink } from "../PreviewUrl";
import SyntaxHighlighter from "../SyntaxHighlighter";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  listSlackIntegrationsOptions,
  slackPreinstallOptions,
  updateSlackIntegrationMutation,
} from "@/client/@tanstack/react-query.gen";
import { Feature } from "@/features";
import { AxiosErrorBox } from "../Error";
import { Skeleton } from "../ui/skeleton";
import SlackImageUpload from "./SlackImageUpload";
import { stringHandler, useQueryState } from "@/hooks/useQueryState";

function CodeBlockWithCopy({ code }: { code: string }) {
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 2000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
      setTimeout(() => setCopySuccess(""), 2000);
    }
  };

  return (
    <div className="relative rounded-lg bg-slate-800 p-4">
      <pre className="max-h-[400px] text-sm text-slate-50">
        <SyntaxHighlighter
          language="markup"
          code={code}
          style="vsc-dark-plus"
          wrapLongLines={false}
        />
      </pre>
      <div className="absolute right-4 top-4 flex items-center gap-2">
        {copySuccess && (
          <span className="text-sm text-green-400">{copySuccess}</span>
        )}
        <Button
          onClick={copyToClipboard}
          variant="ghost"
          type="button"
          size="sm"
          className="text-sm font-medium text-slate-200 hover:bg-slate-700/80 hover:text-slate-50"
        >
          {copySuccess ? (
            <Check className="mr-2 h-4 w-4" />
          ) : (
            <Copy className="mr-2 h-4 w-4" />
          )}
          Copy Snippet
        </Button>
      </div>
    </div>
  );
}

const SlackIntegrationCard = ({
  integration,
}: {
  integration: SlackIntegration;
}) => {
  const { projectId } = useParams();
  const [imageKey, setImageKey] = useState(Date.now());
  const [imageError, setImageError] = useState(false);

  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const updateSlackIntegration = useMutation({
    ...updateSlackIntegrationMutation({}),
    onSuccess: () => {
      setImageKey(Date.now());
      setImageError(false); // Reset error state on successful upload
      toast.success("Bot icon updated successfully");
    },
    onError: () => {
      toast.error("Failed to update bot icon");
    },
  });

  const handleImageUpload = async (file: File) => {
    await updateSlackIntegration.mutateAsync({
      path: {
        project_id: Number(projectId),
        slack_integration_id: integration.id,
      },
      body: { file },
    });
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Card className="mb-4 p-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="w-10 h-10 bg-gray-100 rounded-lg flex items-center justify-center overflow-hidden">
            {!imageError ? (
              <img
                src={`${import.meta.env.VITE_API_BASE_URL}/v1/slack/${integration.id}/bot/image?${imageKey}`}
                alt="Bot Image"
                className="w-full h-full object-cover rounded-lg"
                onError={handleImageError}
              />
            ) : (
              <Slack className="w-6 h-6 text-gray-400" />
            )}
          </div>

          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <span className="text-muted-foreground">Team</span>
              <span>#{integration.team_id}</span>
              <span className="text-muted-foreground">
                added on {formatDate(integration.created_at)}
              </span>
            </div>
          </div>
        </div>
        <SlackImageUpload onImageUpload={handleImageUpload} />
      </div>
    </Card>
  );
};

const SlackIntegrationList = () => {
  const { projectId } = useParams();
  const { data, isPending, error } = useQuery({
    ...listSlackIntegrationsOptions({
      path: { project_id: Number(projectId) },
    }),
  });

  if (error) return <AxiosErrorBox error={error} />;
  if (isPending) return <Skeleton />;

  if (!data.data.length) {
    return (
      <div className="text-gray-500 text-center mt-4">
        No active Slack integrations found.
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-lg font-semibold text-gray-800 mt-4">
        Active Slack Integrations
      </h2>
      <div className="space-y-2 mt-1">
        {data.data.map((integration) => (
          <SlackIntegrationCard
            key={integration.id}
            integration={integration}
          />
        ))}
      </div>
    </div>
  );
};

const SlackIntegrationTab = ({ frame }: { frame: FrameRead }) => {
  const slackPreinstallQuery = useQuery({
    ...slackPreinstallOptions({
      path: { project_id: frame.project_id },
      query: { alias_id: frame.alias_id, user_pool_id: frame.user_pool_id },
    }),
    enabled: false,
  });

  const handleInstall = async () => {
    const { data, isPending, error } = await slackPreinstallQuery.refetch();
    if (error) {
      toast.error("Failed to install Slack bot");
      return;
    }
    if (isPending) {
      toast.info("Redirecting to Slack...");
      return;
    }
    const installUrl = `${import.meta.env.VITE_API_BASE_URL}/slack/install?nonce=${data.nonce}`;
    window.open(installUrl, "_blank");
  };

  return (
    <>
      <Alert>
        <div className="flex items-center gap-2">
          <BeakerIcon className="h-4 w-4 text-yellow-600" />
          <AlertTitle className="mb-0 text-yellow-600">Beta Feature</AlertTitle>
        </div>
        <AlertDescription className="ml-6 text-sm text-gray-700">
          Slack integration is currently in beta. Some features may be unstable
          or subject to change.
        </AlertDescription>
      </Alert>
      <div className="mt-4 flex flex-col gap-4">
        <div className="flex justify-center">
          <button
            onClick={handleInstall}
            disabled={slackPreinstallQuery.isFetching}
            className="inline-block"
            type="button"
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </button>
        </div>
        <p className="text-sm text-gray-600 text-center">
          Click the button above to install the bot in your Slack workspace.
          You'll be redirected to Slack's authorization page.
        </p>
      </div>
      <SlackIntegrationList />
    </>
  );
};

const IntegrationTab = ({ frame }: { frame: FrameRead }) => {
  const [integration, setIntegration] = useQueryState(
    "integration",
    "launcher",
    stringHandler
  );

  const launcherCode = `<script type="text/javascript">
  $botbrains = [];
  $botbrains.push(["set", "config", {
      frameId: '${frame.id}',
  }]);
  (function(){d = document;s = d.createElement('script');s.src = '${import.meta.env.VITE_CHAT_URL}/loader.js';s.type = 'module';s.async = 1;d.getElementsByTagName('head')[0].appendChild(s);})();
</script>`;

  const iframeCode = `<iframe 
  src="${import.meta.env.VITE_CHAT_URL}/loader?frameId=${frame.id}&mode=fullscreen" 
  width="100%" 
  height="100%" 
  style="border: none; min-height: 500px;"
  allow="fullscreen; clipboard-write; clipboard-read"
  frameborder="0"
/>`;

  const inlineCodeJs = `<script type="text/javascript">
  $botbrains = [];
  $botbrains.push(["set", "config", {
      frameId: '${frame.id}',
      mode: "inline",
      // Adjust this selector as needed. Accepts anything that 
      // can be targeted with document.querySelector().
      selector: "#botbrains-inline-chat",
  }]);
  (function(){d = document;s = d.createElement('script');s.src = '${import.meta.env.VITE_CHAT_URL}/loader.js';s.type = 'module';s.async = 1;d.getElementsByTagName('head')[0].appendChild(s);})();
</script>`;
  const inlineCodeHtml = `<div id="botbrains-inline-chat" style="max-width: 56rem; height: 80vh;"/>`;

  return (
    <Card className="w-full rounded-xl border border-gray-100 bg-white/80 shadow-none">
      <CardHeader className="border-b border-gray-100 pb-4">
        <div className="flex items-center gap-2">
          <Code className="h-5 w-5 text-primary" />
          <CardTitle className="text-lg font-semibold text-gray-800">
            Integration Options
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="space-y-6 p-6">
        <Tabs
          value={integration}
          onValueChange={setIntegration}
          className="w-full"
        >
          <TabsList className="grid w-full grid-cols-4">
            <TabsTrigger value="launcher">Launcher</TabsTrigger>
            <TabsTrigger value="inline">Inline</TabsTrigger>
            <TabsTrigger value="slack">Slack</TabsTrigger>
            <TabsTrigger value="iframe">iFrame</TabsTrigger>
          </TabsList>

          <TabsContent value="launcher" className="mt-6">
            <Alert>
              <div className="flex items-center gap-2">
                <Check className="h-4 w-4 text-green-600" />
                <AlertTitle className="mb-0">Recommended</AlertTitle>
              </div>
              <AlertDescription className="ml-6 text-sm text-gray-700">
                Add this code snippet to embed the chat as a widget that appears
                in the corner of your page. It loads asynchronously and can be
                placed anywhere in your HTML.
              </AlertDescription>
            </Alert>
            <div className="mt-4">
              <CodeBlockWithCopy code={launcherCode} />
            </div>
          </TabsContent>
          <TabsContent value="inline" className="mt-6 space-y-4">
            <Alert>
              <div className="flex items-center gap-2">
                <Check className="h-4 w-4 text-green-600" />
                <AlertTitle className="mb-0">Recommended</AlertTitle>
              </div>
              <AlertDescription className="ml-6 text-sm text-gray-700">
                <p>
                  Add this code snippet to embed the chat as an inline widget
                  that appears inlined into your website. Very useful for
                  <ol className="list-disc ml-4">
                    <li>FAQ pages</li>
                    <li>contact forms</li>
                    <li>application sidebars</li>
                  </ol>
                  You need to control height and width of the chat.
                </p>
              </AlertDescription>
            </Alert>
            <Alert>
              <div className="flex items-center gap-2">
                <AlertCircle className="h-4 w-4 text-red-600" />
                <AlertTitle className="mb-0">
                  Important: Choose One Integration Method Per Page
                </AlertTitle>
              </div>
              <AlertDescription className="ml-6 text-sm text-gray-700">
                <p>You must choose between:</p>
                <ul className="list-disc ml-4 mt-2">
                  <li>Launcher bubble (floating chat widget)</li>
                  <li>Inline chat (embedded in your page)</li>
                </ul>
                <p className="mt-2">
                  Using both on the same page is not supported. However, you can
                  use different methods on different pages of your website
                  (e.g., inline chat on your FAQ page and launcher bubble on
                  your homepage).
                </p>
              </AlertDescription>
            </Alert>
            <CodeBlockWithCopy code={inlineCodeJs} />
            <p className="p-2 text-sm text-gray-700">
              {" "}
              The chat will be rendered as a parent of the `selector`. Either
              change the selector or place the below `div` where you would like
              our app to render on your website.
            </p>
            <CodeBlockWithCopy code={inlineCodeHtml} />
          </TabsContent>

          <TabsContent value="iframe" className="mt-6">
            <Alert>
              <div className="flex items-center gap-2">
                <AlertCircle className="h-4 w-4 text-red-600" />
                <AlertTitle className="mb-0 text-red-600">
                  Warning (deprecated)
                </AlertTitle>
              </div>
              <AlertDescription className="ml-6 text-sm text-gray-700">
                Use this code to embed the chat as a <strong>fullscreen</strong>{" "}
                iframe. You will not be able to use the Web SDK. Not all
                features of Users will work in this mode.
              </AlertDescription>
            </Alert>
            <div className="mt-4">
              <CodeBlockWithCopy code={iframeCode} />
            </div>
          </TabsContent>

          <TabsContent value="slack" className="mt-6">
            <SlackIntegrationTab frame={frame} />
          </TabsContent>
        </Tabs>
        <Feature flag="preview-url">
          <div className="space-x-2">
            <PreviewUrlButton
              projectId={Number(frame.project_id)}
              frameId={frame.id}
              className="text-white"
            />
            <PreviewUrlLink
              projectId={Number(frame.project_id)}
              frameId={frame.id}
              className="underline"
            >
              Jump to Preview Url
            </PreviewUrlLink>
          </div>
        </Feature>
      </CardContent>
    </Card>
  );
};

export default IntegrationTab;
