import { Calendar, Sun, Moon } from "lucide-react";
import { MultiSelect } from "../multi-select";
import { useFormContext } from "react-hook-form";
import type { DataProviderValues } from "./DataProviderForm";
import { CronTab } from "@/client";

const dayOptions = [
  { label: "Monday", value: "1", icon: Sun },
  { label: "Tuesday", value: "2", icon: Sun },
  { label: "Wednesday", value: "3", icon: Sun },
  { label: "Thursday", value: "4", icon: Sun },
  { label: "Friday", value: "5", icon: Sun },
  { label: "Saturday", value: "6", icon: Moon },
  { label: "Sunday", value: "0", icon: Moon },
];

const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const CronScheduler = () => {
  const form = useFormContext<DataProviderValues>();

  // Handle day selection and cron schedule updates
  const handleDayChange = (selectedDays: string[]) => {
    if (selectedDays.length === 0) {
      // If no days selected, set schedule to null
      form.setValue("schedule", null);
      return;
    }

    // Generate random minute (0-59)
    const randomMinute = getRandomInt(0, 59);
    // Random hour between 1-5 o clock
    const randomHour = getRandomInt(1, 5);

    // Create cron schedule
    const schedule = {
      minute: randomMinute.toString(),
      hour: randomHour.toString(),
      day_of_week: selectedDays.sort().join(","),
      day_of_month: "*",
      month_of_year: "*",
    } as CronTab;

    form.setValue("schedule", schedule);
  };

  // Convert current schedule back to selected days
  const getCurrentSelectedDays = () => {
    const schedule = form.watch("schedule");
    if (!schedule || !schedule.day_of_week || schedule.day_of_week === "*") {
      return [];
    }
    return schedule.day_of_week.split(",");
  };

  return (
    <div className="flex items-center gap-4 rounded-lg border p-4">
      <div className="flex items-center gap-2">
        <Calendar className="h-4 w-4 text-primary" />
        <div>
          <div className="text-sm font-medium">Schedule</div>
          <div className="text-xs text-muted-foreground">
            Select days to run
          </div>
        </div>
      </div>

      <div className="flex-1">
        <MultiSelect
          options={dayOptions}
          placeholder="Select days..."
          onValueChange={handleDayChange}
          modalPopover={true}
          defaultValue={getCurrentSelectedDays()}
          maxCount={7}
        />
      </div>
    </div>
  );
};

export default CronScheduler;
