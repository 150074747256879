import { useCurrentProjectBreadcrumbs } from "../../hooks";

import { useParams } from "react-router-dom";

import { Skeleton } from "@/components/ui/skeleton";

import QueryDateRangePicker from "../QueryDateRangePicker";
import NavigationHeader from "../sidebar/NavigationHeader";
import {
  nullableDateHandler,
  stringArrayHandler,
  useQueryParam,
  useQueryState,
} from "@/hooks/useQueryState";
import { MultiSelect } from "../multi-select";
import { useQuery } from "@tanstack/react-query";
import { listTopicsOptions } from "@/client/@tanstack/react-query.gen";
import PageHeader from "../PageHeader";
import ConversationsMetadataListV2 from "./ConversationList";
import { Button } from "../ui/button";
import { FilterX } from "lucide-react";

export default function ConversationsListPage() {
  const { projectId } = useParams();
  const [aliases] = useQueryState("alias", [], stringArrayHandler);

  const since = useQueryParam("since", null, nullableDateHandler);
  const until = useQueryParam("until", null, nullableDateHandler);

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Conversations" },
  ];

  const [topics, setTopics] = useQueryState("topic", [], stringArrayHandler);
  const [userIds, setUserIds] = useQueryState(
    "user",
    [],
    stringArrayHandler,
    true
  );

  const TopicSelection = () => {
    const { data, isPending, error } = useQuery({
      ...listTopicsOptions({
        path: {
          project_id: Number(projectId),
        },
      }),
    });

    if (isPending) {
      return <Skeleton />;
    }
    if (error) {
      console.error(error);
      return null;
    }

    return (
      <MultiSelect
        placeholder="All Topics"
        defaultValue={topics}
        onValueChange={setTopics}
        options={data.data.map((topic) => ({
          label: topic.title,
          value: topic.id,
        }))}
        maxCount={1}
        disabled={data.data.length === 0}
      />
    );
  };

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <PageHeader title="Conversations">
        {userIds.length > 0 && (
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              className="h-8 px-3 flex items-center gap-2"
              onClick={() => setUserIds([])}
            >
              <FilterX className="w-4 h-4 mr-2" />
              Clear Filters
            </Button>
          </div>
        )}
      </PageHeader>
      <main className="mt-2">
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="max-w-xl">
            <TopicSelection />
          </div>
          <QueryDateRangePicker forceDateRange={false} />
        </div>

        <ConversationsMetadataListV2
          projectId={Number(projectId)}
          since={since}
          until={until}
          aliases={aliases}
          topics={topics}
          userIds={userIds}
        />
      </main>
    </>
  );
}
