import React from "react";
import { AlertCircle, Languages } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Localization } from "@/client";
import { LanguageDisplay } from "./LanguageDisplay";
import { findClosestLanguageMatch } from "@/ietf-bcp-47";

interface LocaleUpdateDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  locales: Localization[];
  changedLocales: Localization[];
  onConfirm: (
    baseLocale: Localization,
    unchangedLocales: Localization[]
  ) => void;
  onSkip: () => void;
  isTranslating: boolean;
}

const LocaleUpdateDialog: React.FC<LocaleUpdateDialogProps> = ({
  open,
  onOpenChange,
  locales,
  changedLocales,
  onConfirm,
  onSkip,
  isTranslating,
}) => {
  const [selectedBaseLocale, setSelectedBaseLocale] =
    React.useState<string>("");

  // Reset selected locale when dialog opens
  React.useEffect(() => {
    if (open && changedLocales.length > 0) {
      setSelectedBaseLocale(changedLocales[0].locale);
    }
  }, [open, changedLocales]);

  const unchangedLocales = locales.filter(
    (loc) => !changedLocales.some((changed) => changed.locale === loc.locale)
  );

  if (unchangedLocales.length === 0) {
    return null;
  }

  const handleConfirm = () => {
    const baseLocale = locales.find((loc) => loc.locale === selectedBaseLocale);
    if (baseLocale) {
      onConfirm(baseLocale, unchangedLocales);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <Languages className="h-5 w-5" />
            Update Other Languages?
          </DialogTitle>
          <DialogDescription>
            You've updated {changedLocales.length} out of {locales.length}{" "}
            languages. Would you like to update the remaining{" "}
            {unchangedLocales.length} languages using AI translation?
          </DialogDescription>
        </DialogHeader>

        <div className="py-4">
          <Alert>
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              Select which updated language should be used as the base for
              translation:
            </AlertDescription>
          </Alert>

          <div className="mt-4">
            <Select
              value={selectedBaseLocale}
              onValueChange={(value: string) => setSelectedBaseLocale(value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select source language" />
              </SelectTrigger>
              <SelectContent>
                {changedLocales.map((locale) => (
                  <SelectItem key={locale.locale} value={locale.locale}>
                    <LanguageDisplay
                      // NOTE(memben): we will always have a match here
                      language={findClosestLanguageMatch(locale.locale)!}
                      showNative={false}
                      showCountryName={false}
                    />
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        <DialogFooter className="flex justify-between sm:justify-between">
          <Button
            type="button"
            variant="ghost"
            onClick={onSkip}
            disabled={isTranslating}
          >
            Skip Translation
          </Button>
          <Button
            type="button"
            onClick={handleConfirm}
            disabled={!selectedBaseLocale || isTranslating}
          >
            {isTranslating ? "Translating..." : "Update Languages"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LocaleUpdateDialog;
