import { minimatch } from "minimatch";
import { z } from "zod";

import parsePhoneNumber from "libphonenumber-js";
import { zUserUpdate } from "@/client/zod.gen";

// https://github.com/colinhacks/zod/issues/3378#issuecomment-2067591844

export const zPhoneNumber = z.string().transform((value, ctx) => {
  const phoneNumber = parsePhoneNumber(value);

  if (!phoneNumber?.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid phone number",
    });
    return z.NEVER;
  }

  return phoneNumber.formatInternational();
});

const nullableStringToJSONSchema = z
  .string()
  .nullable()
  .transform((str, ctx) => {
    if (str === "" || str === null) return null;
    try {
      return JSON.parse(str);
    } catch (e) {
      ctx.addIssue({ code: "custom", message: "Invalid JSON" });
      return z.NEVER;
    }
  });

const CleanStrRegex = /^[a-zA-Z0-9_\- ]+$/; // as per API spec

const DescribableSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .regex(CleanStrRegex, {
      message:
        "Name can only contain alphanumeric characters, underscores, hyphens, and spaces.",
    }),
  description: z.string(),
});

const MessageSchema = z.object({
  role: z.literal("user").or(z.literal("assistant")),
  content: z.string().min(1, {
    message: "Content must be at least 1 character.",
  }),
});

export const ConversationSchema = z.object({
  messages: z.array(MessageSchema).refine(
    (messages) => {
      if (messages.length === 0) return true;
      if (messages[0].role !== "user") return false;
      for (let i = 1; i < messages.length; i++) {
        if (messages[i].role === messages[i - 1].role) return false;
      }
      return true;
    },
    {
      message: "Roles must alternate starting with 'user'",
    }
  ),
});

const SemanticallyEquivalentCheckSchema = z.object({
  comparator: z.literal("SEMANTICALLY_EQUIVALENT"),
  expected: z
    .string()
    .min(4, { message: "The expected output must be at least 4 characters." }),
  threshold: z
    .number()
    .min(0)
    .max(100)
    .transform((val) => val / 100),
});

const MatchesCheckSchema = z.object({
  comparator: z.literal("MATCHES"),
  pattern: z.string().min(1, {
    message: "Pattern must be at least 1 character.",
  }),
});

const NotMatchesCheckSchema = z.object({
  comparator: z.literal("NOT_MATCHES"),
  pattern: z.string().min(1, {
    message: "Pattern must be at least 1 character.",
  }),
});

const stringToNonEmptyArray = (val: string) =>
  val
    .split(",")
    .map((v) => v.trim())
    .filter((v) => v.length > 0);

const ClassifyAsCheckSchema = z
  .object({
    comparator: z.literal("CLASSIFY_AS"),
    options: z.string().transform(stringToNonEmptyArray),
    expected: z.string().transform(stringToNonEmptyArray),
  })
  .superRefine((data, ctx) => {
    if (data.options.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Options list must not be empty.",
        path: ["options"],
      });
    }
    if (data.expected.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Expected list must not be empty.",
        path: ["expected"],
      });
    }
    if (!data.expected.every((val: string) => data.options.includes(val))) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Expected values must be in the options list.",
        path: ["expected"],
      });
    }
  });

export const CheckSchema = z.union([
  SemanticallyEquivalentCheckSchema,
  MatchesCheckSchema,
  NotMatchesCheckSchema,
  ClassifyAsCheckSchema,
]);

export const TestSuiteSchema = DescribableSchema.extend({
  project_id: z.number(),
});

export const TestCaseUpdateSchema = DescribableSchema.extend({
  input: ConversationSchema,
  check: CheckSchema,
});

export const TestCaseCreateSchema = TestCaseUpdateSchema.extend({
  testsuite_id: z.string(),
});

export const TestCaseSchema = TestCaseCreateSchema.extend({});

export const TestSuiteRunSchema = DescribableSchema.extend({
  project_id: z.number(),
  testsuite_id: z.string(),
  deployment_id: z.number(),
});

// FrameTheme schema
const FrameThemeSchema = z.object({
  name: z.enum(["light", "dark"]),
  brand: z.string(),
  header_bg: z.string(),
  chat: z.string(),
  chat_text: z.string(),
  chat_text_light: z.string(),
});

const emptyStringToNull = z
  .string()
  .nullable()
  .transform((str) => (str === "" ? null : str));

const nullablePixelValue = emptyStringToNull.pipe(
  z
    .string()
    .regex(/^\d+px$/, {
      message: "Invalid pixel value, must be a number followed by 'px'",
    })
    .nullable()
);

// LauncherSettings schema
export const LauncherSettingsSchema = z.object({
  button_color: z.string(),
  alignment: z.enum(["left", "right"]).default("right"),
  side_margin: nullablePixelValue,
  bottom_margin: nullablePixelValue,
});

// Localization schema
export const LocalizationSchema = z.object({
  locale: z.string(),
  messages: z.array(MessageSchema),
  suggestions: z.array(z.string().min(1)),
});

const dataUrlSchema = z
  .string()
  .regex(/^data:image\/(png|jpeg|svg\+xml);base64,/);

const nullableUrl = emptyStringToNull.pipe(z.string().url().nullable());

export const FrameSettingsSchema = z.object({
  assistant_name: z.string().default("Assistant"),
  allowed_origins: z.array(z.string().url()),
  themes: z.array(FrameThemeSchema),
  locales: z.array(LocalizationSchema).min(1),
  header_image_url: z.union([nullableUrl, dataUrlSchema]),
  terms_of_service_url: nullableUrl,
  privacy_policy_url: nullableUrl,
  show_sources: z.boolean().default(false),
  show_popup: z.boolean().default(false),
  launcher_settings: LauncherSettingsSchema,
});

export const ProjectSchema = DescribableSchema.extend({
  support_email: z.string().email().nullable(),
  website_url: nullableUrl,
  support_website_url: nullableUrl,
});

const isValidGlob = (pattern: string) => {
  try {
    minimatch("any-string", pattern);
    return true;
  } catch {
    return false;
  }
};

const EnqueueStrategy = z.enum(["same-origin", "same-hostname", "same-domain"]);

const CronTabSchema = z
  .object({
    minute: z.string().default("0"),
    hour: z.string().default("0"),
    day_of_week: z.string().default("*"),
    day_of_month: z.string().default("*"),
    month_of_year: z.string().default("*"),
  })
  .refine(
    (data) => {
      // Basic cron validation - could be expanded for more thorough validation
      const cronRegex = /^[\d\s,/*-]+$/;
      return Object.values(data).every((val) => cronRegex.test(val));
    },
    {
      message: "Invalid crontab format",
    }
  );

const CrawlerJobSchema = z.object({
  urls: z
    .array(
      z
        .string()
        .url()
        .refine((url) => url.startsWith("https://"), {
          message: "URL must use HTTPS",
        })
    )
    .min(1),
  scope: EnqueueStrategy,
  render_mode: z.enum(["js", "no-js", "auto"]).default("auto"),
  limit: z.number().int().min(1).max(20000).default(50),
  limit_concurrency: z.number().int().min(1).max(50).default(10),
  include_globs: z
    .array(
      z.string().refine(isValidGlob, {
        message: "Invalid include glob pattern",
      })
    )
    .default([]),
  exclude_globs: z
    .array(
      z.string().refine(isValidGlob, {
        message: "Invalid exclude glob pattern",
      })
    )
    .default([]),
});

export const DataProviderSchema = z
  .object({
    name: z.string(),
    project_id: z.number().int().positive(),
    schedule: CronTabSchema.nullable(),
    data_provider_type: z.enum(["human", "webcrawler"]),
    definition: CrawlerJobSchema.nullable(),
  })
  .refine(
    (data) => {
      if (data.data_provider_type === "human") {
        return data.schedule === null && data.definition === null;
      }
      return data.definition !== null;
    },
    {
      message: "Invalid configuration for data provider type",
    }
  );

export const UserUpdateSchema = zUserUpdate.extend({
  phone: zPhoneNumber.nullable().optional(),
  external_attributes: nullableStringToJSONSchema,
});
