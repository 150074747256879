import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  SlidersHorizontal,
  ChevronRight,
  ThumbsUp,
  CircleCheck,
  Globe2,
  History,
  SendHorizontal,
  ThumbsDown,
  XCircle,
  AlertCircle,
  Smile,
  Meh,
  Frown,
  CircleHelp,
  FileEdit,
  MailCheck,
  MailX,
} from "lucide-react";
import MultiSelectFilter from "./MultiSelectFilter";
import { stringArrayHandler, useQueryState } from "@/hooks/useQueryState";
import { findClosestLanguageMatch } from "@/ietf-bcp-47";
import React from "react";

const RatingFilter = () => {
  const [ratings, setRatings] = useQueryState("rating", [], stringArrayHandler);
  return (
    <MultiSelectFilter
      options={[
        { label: "Liked", value: "thumbsUp", icon: ThumbsUp },
        { label: "Disliked", value: "thumbsDown", icon: ThumbsDown },
      ]}
      selected={ratings}
      onSelect={(value) => setRatings([...ratings, value])}
      onUnselect={(value) => setRatings(ratings.filter((v) => v !== value))}
    />
  );
};

const CompletenessFilter = () => {
  const [completeness, setCompleteness] = useQueryState(
    "completeness",
    [],
    stringArrayHandler
  );
  return (
    <MultiSelectFilter
      options={[
        { label: "Complete", value: "Complete", icon: CircleCheck },
        { label: "Incomplete", value: "Incomplete", icon: XCircle },
        { label: "Partially Resolved", value: "Partial", icon: AlertCircle },
        {
          label: "Clarifying",
          value: "Clarifying",
          icon: CircleHelp,
        },
      ]}
      selected={completeness}
      onSelect={(value) => setCompleteness([...completeness, value])}
      onUnselect={(value) =>
        setCompleteness(completeness.filter((v) => v !== value))
      }
    />
  );
};

const SentimentFilter = () => {
  const [sentiment, setSentiment] = useQueryState(
    "sentiment",
    [],
    stringArrayHandler
  );
  return (
    <MultiSelectFilter
      options={[
        { label: "Positive", value: "Positive", icon: Smile },
        { label: "Neutral", value: "Neutral", icon: Meh },
        { label: "Negative", value: "Negative", icon: Frown },
      ]}
      selected={sentiment}
      onSelect={(value) => setSentiment([...sentiment, value])}
      onUnselect={(value) => setSentiment(sentiment.filter((v) => v !== value))}
    />
  );
};

const LANGUAGE_OPTIONS = ["de", "en", "es", "fr", "it", "pt", "ru", "zh"];

const LanguageFilter = () => {
  const [languages, setLanguages] = useQueryState(
    "languages",
    [],
    stringArrayHandler
  );
  const options = LANGUAGE_OPTIONS.map((lang) => {
    const langMatch = findClosestLanguageMatch(lang);
    const label = langMatch
      ? langMatch?.emoji + " " + langMatch?.shortLocalizedName
      : lang;
    return {
      label: label,
      value: lang,
    };
  });

  return (
    <MultiSelectFilter
      options={options}
      selected={languages}
      onSelect={(value) => setLanguages([...languages, value])}
      onUnselect={(value) => setLanguages(languages.filter((v) => v !== value))}
    />
  );
};

const PagesFilter = () => {
  const [pages, setPages] = useQueryState("pages", [], stringArrayHandler);
  const [inputValue, setInputValue] = React.useState("https://");

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      setPages([...pages, inputValue.trim()]);
      setInputValue("https://");
    }
  };

  return (
    <div className="p-3 space-y-3">
      <Input
        placeholder="Enter page URL or path..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        className="h-8"
      />
      {pages.length > 0 && (
        <div className="space-y-2">
          {pages.map((page, index) => (
            <div
              key={index}
              className="flex items-center justify-between text-sm"
            >
              <span className="truncate flex-1">{page}</span>
              <Button
                variant="ghost"
                size="sm"
                className="h-6 w-6 p-0"
                onClick={() => setPages(pages.filter((_, i) => i !== index))}
              >
                <XCircle className="h-4 w-4" />
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const HandoffFilter = () => {
  const [handoff, setHandoff] = useQueryState(
    "handoff",
    [],
    stringArrayHandler
  );
  return (
    <MultiSelectFilter
      options={[
        { label: "Submitted", value: "SUBMITTED", icon: MailCheck },
        { label: "Cancelled", value: "CANCELLED", icon: MailX },
        { label: "Drafted", value: "DRAFT", icon: FileEdit },
      ]}
      selected={handoff}
      onSelect={(value) => setHandoff([...handoff, value])}
      onUnselect={(value) => setHandoff(handoff.filter((v) => v !== value))}
    />
  );
};

const categories = [
  {
    label: "Rating",
    icon: <ThumbsUp className="w-4 h-4" />,
    component: <RatingFilter />,
  },
  {
    label: "Completeness",
    icon: <CircleCheck className="w-4 h-4" />,
    component: <CompletenessFilter />,
  },
  {
    label: "Sentiment",
    icon: <Smile className="w-4 h-4" />,
    component: <SentimentFilter />,
  },
  {
    label: "Language",
    icon: <Globe2 className="w-4 h-4" />,
    component: <LanguageFilter />,
  },
  {
    label: "Visited Pages",
    icon: <History className="w-4 h-4" />,
    component: <PagesFilter />,
  },
  {
    label: "Handoff",
    icon: <SendHorizontal className="w-4 h-4" />,
    component: <HandoffFilter />,
  },
];

export const AdvancedFilter = () => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="h-8 px-3 flex items-center gap-2">
          <SlidersHorizontal className="w-4 h-4" />
          <span>Advanced Filters</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-64 p-0" align="start">
        <div className="py-1">
          {categories.map((category) => (
            <Popover key={category.label}>
              <PopoverTrigger asChild>
                <div className="flex items-center justify-between px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm group">
                  <div className="flex items-center gap-2">
                    {category.icon}
                    <span>{category.label}</span>
                  </div>
                  <ChevronRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                </div>
              </PopoverTrigger>
              <PopoverContent
                className="w-64 p-0"
                align="start"
                side="right"
                alignOffset={-5}
                sideOffset={-4}
              >
                <div className="py-1">
                  <div className="px-3 py-2 text-sm font-medium border-b">
                    {category.label}
                  </div>
                  {category.component}
                </div>
              </PopoverContent>
            </Popover>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
