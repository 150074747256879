"use client";

import { GripVertical, Plus, X } from "lucide-react";
import React from "react";
import { Path, PathValue, FieldValues, useFormContext } from "react-hook-form";

import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Sortable, SortableDragHandle, SortableItem } from "./ui/sortable";

type ListFieldPath<T> = Path<T> & string;

interface DynamicListFieldProps<TFormValues extends FieldValues, TFieldValue> {
  name: ListFieldPath<TFormValues>;
  label: string;
  description?: string;
  icon?: React.ReactNode;
  defaultValue: TFieldValue;
  renderField: (field: {
    value: TFieldValue;
    onChange: (...event: any[]) => void;
    onBlur: () => void;
    name: string;
    ref?: React.Ref<any>;
  }) => React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  addButtonLabel?: string;
}

export const DynamicListField = <TFormValues extends FieldValues, TFieldValue>({
  name,
  label,
  description,
  icon,
  defaultValue,
  renderField,
  children,
  className = "space-y-4 rounded-lg border border-gray-100 bg-gray-50/50 p-4",
  addButtonLabel = "Add Item",
}: DynamicListFieldProps<TFormValues, TFieldValue>) => {
  const form = useFormContext<TFormValues>();
  const items = (form.watch(name) as TFieldValue[]) || [];

  const append = () => {
    const newValue = [...items, defaultValue] as PathValue<
      TFormValues,
      typeof name
    >;
    form.setValue(name, newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const remove = (index: number) => {
    const newItems = items.filter((_: TFieldValue, i: number) => i !== index);
    const newValue = newItems as PathValue<TFormValues, typeof name>;
    form.setValue(name, newValue, { shouldValidate: true });
  };

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center gap-2">
            {icon}
            <FormLabel className="text-sm font-medium text-gray-700">
              {label}
            </FormLabel>
          </div>
          {description && (
            <FormDescription className="text-sm text-gray-500">
              {description}
            </FormDescription>
          )}
        </div>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={append}
          className="text-sm"
        >
          <Plus className="mr-2 h-4 w-4" />
          {addButtonLabel}
        </Button>
      </div>

      {items.map((_: TFieldValue, index: number) => (
        <div key={`${name}-${index}`} className="flex gap-2">
          <FormField
            control={form.control}
            name={`${name}.${index}` as Path<TFormValues>}
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormControl>{renderField(field)}</FormControl>
                <FormMessage className="text-xs text-red-500" />
              </FormItem>
            )}
          />
          <Button
            type="button"
            variant="ghost"
            size="sm"
            onClick={() => remove(index)}
            className="h-8 w-8 p-0 transition-colors hover:bg-red-50 hover:text-red-500"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      ))}
      {children}
    </div>
  );
};

export const SortableListField = <
  TFormValues extends FieldValues,
  TFieldValue,
>({
  name,
  label,
  description,
  icon,
  defaultValue,
  renderField,
  children,
  className = "space-y-4 rounded-lg border border-gray-100 bg-gray-50/50 p-4",
  addButtonLabel = "Add Item",
}: DynamicListFieldProps<TFormValues, TFieldValue>) => {
  const form = useFormContext<TFormValues>();
  const rawItems = (form.watch(name) as TFieldValue[]) || [];

  // Internal state to maintain stable IDs for items
  const [itemIds, setItemIds] = React.useState<string[]>([]);

  // Ensure each item has an associated ID
  React.useEffect(() => {
    const currentIds = itemIds.slice(0, rawItems.length);
    const newIds = Array.from(
      { length: Math.max(0, rawItems.length - currentIds.length) },
      () => crypto.randomUUID()
    );
    setItemIds([...currentIds, ...newIds]);
  }, [rawItems.length]);

  // Create items with internal IDs for sorting
  const items = rawItems.map((item, index) => ({
    value: item,
    id: itemIds[index] || crypto.randomUUID(),
  }));

  const append = () => {
    const newValue = [...rawItems, defaultValue] as PathValue<
      TFormValues,
      typeof name
    >;
    form.setValue(name, newValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const remove = (index: number) => {
    const newItems = rawItems.filter(
      (_: TFieldValue, i: number) => i !== index
    );
    const newIds = itemIds.filter((_, i) => i !== index);
    setItemIds(newIds);
    const newValue = newItems as PathValue<TFormValues, typeof name>;
    form.setValue(name, newValue, { shouldValidate: true });
  };

  const handleSort = (newSortedItems: typeof items) => {
    // Update the IDs array to match the new order
    const newIds = newSortedItems.map((item) => item.id);
    setItemIds(newIds);

    // Map back to the original value type and update form
    const newValue = newSortedItems.map((item) => item.value) as PathValue<
      TFormValues,
      typeof name
    >;
    form.setValue(name, newValue, { shouldValidate: true });
  };

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center gap-2">
            {icon}
            <FormLabel className="text-sm font-medium text-gray-700">
              {label}
            </FormLabel>
          </div>
          {description && (
            <FormDescription className="text-sm text-gray-500">
              {description}
            </FormDescription>
          )}
        </div>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={append}
          className="text-sm"
        >
          <Plus className="mr-2 h-4 w-4" />
          {addButtonLabel}
        </Button>
      </div>

      <Sortable
        value={items}
        onValueChange={handleSort}
        overlay={
          <div className="flex w-full items-center gap-2 rounded-md border bg-card p-2">
            <div className="h-8 w-8" />
            <div className="h-8 flex-1 rounded-md bg-accent" />
            <div className="h-8 w-8" />
          </div>
        }
      >
        <div className="space-y-2">
          {items.map((item, index) => (
            <SortableItem key={item.id} value={item.id} asChild>
              <div className="flex items-center gap-2">
                <SortableDragHandle
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8 shrink-0"
                >
                  <GripVertical className="h-4 w-4" />
                </SortableDragHandle>
                <FormField
                  control={form.control}
                  name={`${name}.${index}` as Path<TFormValues>}
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormControl>
                        {renderField({
                          ...field,
                          value: item.value,
                        })}
                      </FormControl>
                      <FormMessage className="text-xs text-red-500" />
                    </FormItem>
                  )}
                />

                <Button
                  type="button"
                  variant="ghost"
                  size="sm"
                  onClick={() => remove(index)}
                  className="h-8 w-8 shrink-0 p-0 transition-colors hover:bg-red-50 hover:text-red-500"
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </SortableItem>
          ))}
        </div>
      </Sortable>
      {children}
    </div>
  );
};
