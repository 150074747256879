import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ClerkProvider } from "@clerk/clerk-react";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Toaster } from "@/components/ui/sonner";

import { setupAnalytics } from "./analytics.ts";
import { configureApiClient } from "./api-config.ts";
import AdminDashboard from "./components/AdminDashboard.tsx";
import ConversationPage from "./components/conversations/ConversationPage.tsx";
import Conversations from "./components/conversations/ConversationsListPage.tsx";
import DashboardToken from "./components/DashboardToken.tsx";
import DataProviderPage from "./components/dataproviders/DataProviderPage.tsx";
import DataProvidersPage from "./components/dataproviders/DataProviderListPage.tsx";
import ErrorBoundary from "./components/ErrorBoundary";
import TestCaseCreatePage from "./components/evaluation/TestCaseCreatePage.tsx";
import TestCaseEditPage from "./components/evaluation/TestCaseEditPage.tsx";
import TestCasePage from "./components/evaluation/TestCasePage.tsx";
import TestSuiteCreatePage from "./components/evaluation/TestSuiteCreatePage.tsx";
import TestSuiteEditorPage from "./components/evaluation/TestSuiteEditPage.tsx";
import TestSuitePage from "./components/evaluation/TestSuitePage.tsx";
import TestSuiteRunCreatePage from "./components/evaluation/TestSuiteRunCreatePage.tsx";
import TestSuiteRunPage from "./components/evaluation/TestSuiteRunPage.tsx";
import TestSuiteRunsPage from "./components/evaluation/TestSuiteRunsPage.tsx";
import TestSuitesPage from "./components/evaluation/TestSuitesPage.tsx";
import FrameEditorPage from "./components/integration/FrameEditorPage.tsx";
import FrameEditorSelector from "./components/integration/FrameSelectorPage.tsx";
import MessageSearchPage from "./components/messages/MessageSearchPage.tsx";
import ProfilePage from "./components/profiles/ProfilePage.tsx";
import ProjectDashboard from "./components/ProjectDashboardPage.tsx";
import ProjectMetrics from "./components/charts/ProjectMetricsPage.tsx";
import ProjectsPage from "./components/ProjectsPage.tsx";
import ProjectSalesPage from "./components/ProjectSalesPage.tsx";
import ProjectSettingsPage from "./components/ProjectSettingsPage.tsx";
import EnsureAuthorized from "./EnsureAuthorized.tsx";
import SearchTablesPage from "./components/tables/SearchTablesPage.tsx";
import store from "./store";
import { setupMonitoring } from "./sentry.ts";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import FrameLocaleGallary from "./misc/FrameLocaleGallary.tsx";
import SalesPage from "./components/SalesPage.tsx";
import { SidebarLayout } from "./components/sidebar/SidebarLayout.tsx";
import TopicsVisualizationPage from "./components/topics/TopicsVisualizationPage.tsx";

import TopicsPage2 from "./components/topics/TopicsPage.tsx";
import { isAxiosError } from "axios";
import ListSearchTablesPage from "./components/tables/ListSearchTablesPage.tsx";
import UserListPage from "./components/users/UserListPage.tsx";

// Import your publishable key
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

setupMonitoring();
setupAnalytics();
configureApiClient();

// NOTE(liamvdv): could wrap every route in a React.lazy() load and Suspense.

const router = createBrowserRouter([
  {
    path: "/frame-locale-gallery",
    element: <FrameLocaleGallary />,
  },
  {
    path: "/",
    element: <EnsureAuthorized />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <SidebarLayout />,
        children: [
          { path: "/", element: <ProjectsPage /> },
          {
            path: "/:projectId",
            element: <ProjectDashboard />,
          },
          {
            // NOTE(memben): doesn't need to be nested under a project
            path: "/:projectId/userpool/:userPoolId",
            element: <UserListPage />,
          },
          {
            path: "/:projectId/metrics",
            element: <ProjectMetrics />,
          },
          {
            path: "/:projectId/conversations",
            element: <Conversations />,
          },
          {
            path: "/:projectId/messages/search",
            element: <MessageSearchPage />,
          },
          {
            path: "/:projectId/frames",
            element: <FrameEditorSelector />,
          },
          {
            path: "/:projectId/frames/:frameId",
            element: <FrameEditorPage />,
          },
          {
            path: "/:projectId/conversations/:conversationId",
            element: <ConversationPage />,
          },
          {
            path: "/:projectId/topics",
            element: <TopicsPage2 />,
          },
          {
            path: "/:projectId/topics-visualization",
            element: <TopicsVisualizationPage />,
          },
          {
            path: "/:projectId/dataproviders",
            element: <DataProvidersPage />,
          },
          {
            path: "/:projectId/dataproviders/:providerId",
            element: <DataProviderPage />,
          },
          {
            path: "/:projectId/profiles",
            element: <ProfilePage />,
          },
          {
            path: "/:projectId/testsuites",
            element: <TestSuitesPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId",
            element: <TestSuitePage />,
          },
          {
            path: "/:projectId/testsuites/create",
            element: <TestSuiteCreatePage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/edit",
            element: <TestSuiteEditorPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/runs",
            element: <TestSuiteRunsPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/runs/create",
            element: <TestSuiteRunCreatePage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/runs/:testSuiteRunId",
            element: <TestSuiteRunPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/testcases/:testCaseId",
            element: <TestCasePage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/testcases/:testCaseId/edit",
            element: <TestCaseEditPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/testcases/create",
            element: <TestCaseCreatePage />,
          },
          {
            path: "/:projectId/settings",
            element: <ProjectSettingsPage />,
          },
          {
            path: "/:projectId/tables",
            element: <ListSearchTablesPage />,
          },
          {
            path: "/:projectId/tables/:tableId",
            element: <SearchTablesPage />,
          },
          {
            path: "/:projectId/sales",
            element: <ProjectSalesPage />,
          },
          {
            path: "/sales",
            element: <SalesPage />,
          },
          { path: "/token", element: <DashboardToken /> },
          {
            path: "/admin",
            element: <AdminDashboard />,
          },
          {
            path: "/break",
            element: (
              <button
                onClick={() => {
                  throw new Error("Error Monitoring Test");
                }}
              >
                Error Monitoring: Throw Error
              </button>
            ),
          },
        ],
      },
    ],
  },
]);

const MAX_RETRIES = 3;
// NOTE(memben): 404 debatable - for now we don't retry
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

// https://github.com/TanStack/query/discussions/372#discussioncomment-6023276
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false;
        }
        if (
          isAxiosError(error) &&
          HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)
        ) {
          console.log(`Aborting retry due to ${error.response?.status} status`);
          return false;
        }

        return true;
      },
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-right"
        />
      </QueryClientProvider>
    </ClerkProvider>
    <Toaster />
  </React.StrictMode>
);
