import { formatDistanceToNowStrict } from "date-fns";
import {
  ExternalLink,
  Shapes,
  Database,
  HardDriveDownload,
} from "lucide-react";
import { Link } from "react-router-dom";

import { DataProviderRead } from "@/client/types.gen";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { usePollPendingSnapshots } from "@/hooks";
import { useQuery } from "@tanstack/react-query";
import { listSourcesOptions } from "@/client/@tanstack/react-query.gen";

import LiveTimeDistance from "../LiveTimeDistance";
import {
  HumanUploadButton,
  DataProviderSyncButton,
} from "./DataProviderActions";
import { ProviderDropdown } from "./DataProviderDropdown";

const SourceCount = ({ count }: { count: number }) => (
  <div className="flex items-center gap-1.5 px-3 py-1 bg-gray-50 rounded-full">
    <Database className="h-3.5 w-3.5 text-gray-600" />
    <span className="text-xs font-medium text-gray-700">
      {count} {count === 1 ? "Source" : "Sources"}
    </span>
  </div>
);

export function WebCrawlerProviderRow({
  provider,
}: {
  provider: DataProviderRead;
}) {
  const { snapshots } = usePollPendingSnapshots({
    projectId: provider.project_id,
    dataProviderId: provider.id,
  });

  const latestSnapshot = snapshots ? snapshots[0] : null;
  const isSyncing = latestSnapshot
    ? latestSnapshot.status === "PENDING"
    : false;
  const url = provider.definition?.urls?.[0] as string | null;
  const favicon = url
    ? `https://www.google.com/s2/favicons?domain=${url}&sz=32`
    : null;

  const { data: latestSources } = useQuery({
    ...listSourcesOptions({
      path: { project_id: provider.project_id },
      query: { data_provider_snapshot_id: latestSnapshot?.id },
    }),
    enabled: !!latestSnapshot,
  });

  return (
    <div className="group flex items-center justify-between border-b border-gray-100 hover:bg-gray-50 pt-1">
      <Link
        to={`/${provider.project_id}/dataproviders/${provider.id}`}
        className="flex-1"
      >
        <div className="flex items-center px-4 py-3 cursor-pointer">
          <div className="flex items-center space-x-3">
            {favicon ? (
              <img src={favicon} alt="" className="h-6 w-6" />
            ) : (
              <div className="h-6 w-6 rounded-full bg-gray-200" />
            )}

            <div>
              <h3 className="text-sm font-medium text-gray-900 group-hover:text-blue-600">
                {provider.name}
              </h3>

              {latestSnapshot && (
                <div className="text-xs text-gray-500">
                  {latestSnapshot.end_time ? (
                    <>
                      Last synced{" "}
                      {formatDistanceToNowStrict(latestSnapshot.end_time, {
                        addSuffix: true,
                      })}
                    </>
                  ) : (
                    <>
                      Sync started{" "}
                      <LiveTimeDistance date={latestSnapshot.start_time} />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>

      <div className="flex items-center space-x-4">
        {latestSources && <SourceCount count={latestSources.total} />}

        <div className="flex items-center space-x-1 px-4">
          {url && (
            <TooltipProvider>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => window.open(url, "_blank")}
                  >
                    <ExternalLink className="h-4 w-4 text-gray-500" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Visit website</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}

          <DataProviderSyncButton provider={provider} isSyncing={isSyncing} />

          <ProviderDropdown provider={provider} />
        </div>
      </div>
    </div>
  );
}

export function AnyProviderRow({ provider }: { provider: DataProviderRead }) {
  const { snapshots } = usePollPendingSnapshots({
    projectId: provider.project_id,
    dataProviderId: provider.id,
  });

  const latestSnapshot = snapshots ? snapshots[0] : null;
  const isSyncing = latestSnapshot
    ? latestSnapshot.status === "PENDING"
    : false;

  const { data: latestSources } = useQuery({
    ...listSourcesOptions({
      path: { project_id: provider.project_id },
      query: { data_provider_snapshot_id: latestSnapshot?.id },
    }),
    enabled: !!latestSnapshot,
  });

  return (
    <div className="group flex items-center justify-between border-b border-gray-100 hover:bg-gray-50 pt-1">
      <Link
        to={`/${provider.project_id}/dataproviders/${provider.id}`}
        className="flex-1"
      >
        <div className="flex items-center px-4 py-3 cursor-pointer">
          <div className="flex items-center space-x-3">
            <HardDriveDownload className="h-6 w-6 text-primary" />

            <div>
              <h3 className="text-sm font-medium text-gray-900 group-hover:text-blue-600">
                {provider.name}
              </h3>

              {latestSnapshot && (
                <div className="text-xs text-gray-500">
                  {latestSnapshot.end_time ? (
                    <>
                      Last synced{" "}
                      {formatDistanceToNowStrict(latestSnapshot.end_time, {
                        addSuffix: true,
                      })}
                    </>
                  ) : (
                    <>
                      Sync started{" "}
                      <LiveTimeDistance date={latestSnapshot.start_time} />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>

      <div className="flex items-center space-x-4">
        {latestSources && <SourceCount count={latestSources.total} />}

        <div className="flex items-center space-x-1 px-4">
          <DataProviderSyncButton provider={provider} isSyncing={isSyncing} />
          <ProviderDropdown provider={provider} />
        </div>
      </div>
    </div>
  );
}

export function HumanProviderRow({ provider }: { provider: DataProviderRead }) {
  const { snapshots } = usePollPendingSnapshots({
    projectId: provider.project_id,
    dataProviderId: provider.id,
  });
  const latestSnapshot = snapshots ? snapshots[0] : null;
  const hasActiveSnapshot = latestSnapshot?.status === "PENDING";

  const { data: latestSources } = useQuery({
    ...listSourcesOptions({
      path: { project_id: provider.project_id },
      query: { data_provider_snapshot_id: latestSnapshot?.id },
    }),
    enabled: !!latestSnapshot,
  });

  return (
    <div className="group flex items-center justify-between border-b border-gray-100 hover:bg-gray-50 pt-1">
      <Link
        to={`/${provider.project_id}/dataproviders/${provider.id}`}
        className="flex-1"
      >
        <div className="flex items-center px-4 py-3 cursor-pointer">
          <div className="flex items-center space-x-3">
            <Shapes className="h-6 w-6 text-primary" />
            <div>
              <h3 className="text-sm font-medium text-gray-900 group-hover:text-blue-600">
                {provider.name}
              </h3>
              {latestSnapshot && (
                <div className="text-xs text-gray-500">
                  {latestSnapshot.end_time ? (
                    <>
                      Last edited{" "}
                      {formatDistanceToNowStrict(latestSnapshot.end_time, {
                        addSuffix: true,
                      })}
                    </>
                  ) : (
                    <>
                      Editing since{" "}
                      <LiveTimeDistance
                        date={latestSnapshot.start_time}
                        addSuffix={false}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>

      <div className="flex items-center space-x-4">
        {latestSources && <SourceCount count={latestSources.total} />}

        <div className="flex items-center space-x-1 px-4">
          <HumanUploadButton
            provider={provider}
            hasActiveSnapshot={hasActiveSnapshot}
          />
          <ProviderDropdown provider={provider} />
        </div>
      </div>
    </div>
  );
}
