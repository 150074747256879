import { useParams } from "react-router-dom";
import useFlagEnabled from "@/features";
import {
  LayoutDashboard,
  MessageSquare,
  MessagesSquare,
  BarChart,
  ClipboardCheck,
  Settings,
  BookOpen,
  Users,
  Tags,
  Puzzle,
  Handshake,
} from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { listProjectUsedUserPoolsOptions } from "@/client/@tanstack/react-query.gen";

export function useLocalNav() {
  const { projectId } = useParams();
  const flagTabProfiles = useFlagEnabled("tab-profiles");
  const flagSales = useFlagEnabled("sales");

  const {
    data: userPoolsData,
    isPending,
    error,
  } = useQuery({
    ...listProjectUsedUserPoolsOptions({
      path: {
        project_id: Number(projectId),
      },
    }),
    enabled: projectId !== undefined,
  });

  if (projectId === undefined) {
    return {
      insights: [],
      chatbot: [],
      misc: [],
    };
  }

  if (error) {
    console.error("Error fetching user pools:", error);
  }

  const userPools = userPoolsData?.data ?? [];

  if (userPools.length > 1) {
    console.warn(
      "Multiple user pools are not supported. Using the first user pool."
    );
  }

  const activeUserPool = userPools[0];

  const insights = [
    {
      name: "Dashboard",
      href: `/${projectId}`,
      Icon: LayoutDashboard,
    },
    {
      name: "Conversations",
      href: `/${projectId}/conversations`,
      Icon: MessagesSquare,
    },
    {
      name: "Messages",
      href: `/${projectId}/messages/search`,
      Icon: MessageSquare,
    },
    {
      name: "Users",
      href: activeUserPool
        ? `/${projectId}/userpool/${activeUserPool.id}`
        : "#",
      Icon: Users,
      disabled: isPending || !activeUserPool, // NOTE(memben): does not seem to be used correctly by shadcn
    },
    {
      name: "Topics",
      href: `/${projectId}/topics`,
      Icon: Tags,
    },
    {
      name: "Metrics",
      href: `/${projectId}/metrics`,
      Icon: BarChart,
    },
  ];

  const chatbot = [
    {
      name: "Integration",
      href: `/${projectId}/frames`,
      Icon: Puzzle,
    },
    {
      name: "Knowledge",
      href: `/${projectId}/dataproviders`,
      Icon: BookOpen,
    },
    {
      name: "Evaluation",
      href: `/${projectId}/testsuites`,
      Icon: ClipboardCheck,
    },
  ];

  if (flagTabProfiles) {
    chatbot.push({
      name: "Profiles",
      href: `/${projectId}/profiles`,
      Icon: Users,
    });
  }

  const misc = [
    {
      name: "Settings",
      href: `/${projectId}/settings`,
      Icon: Settings,
    },
  ];

  if (flagSales) {
    misc.push({
      name: "Sales Dashboard",
      href: `/${projectId}/sales`,
      Icon: Handshake,
    });
  }

  return { insights, chatbot, misc };
}
