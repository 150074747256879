import { formatDistanceToNowStrict } from "date-fns";
import { MessageSquare } from "lucide-react";

import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

import Empty from "../Empty";
import { AxiosErrorBox } from "../Error";
import LinkWithQuery from "../LinkWithQuery";
import { useInfiniteQuery } from "@tanstack/react-query";
import { listConversationsInfiniteOptions } from "@/client/@tanstack/react-query.gen";
import { ConversationInsightsRead } from "@/client";
import { useParams } from "react-router-dom";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";

const ConversationCardSkeleton = () => {
  return (
    <Card className="h-full transition-all hover:shadow-md">
      <CardHeader className="flex-row items-center justify-between space-y-0 pb-2">
        <div className="flex items-center space-x-2">
          {/* Time skeleton */}
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-2 w-2 rounded-full" />
          <Skeleton className="h-4 w-16" />
        </div>
      </CardHeader>
      <CardContent className="space-y-2">
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-3/4" />
      </CardContent>
    </Card>
  );
};

const ConversationListSkeleton = () => {
  return (
    <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 lg:grid-cols-3">
      {[...Array(12)].map((_, i) => (
        <ConversationCardSkeleton key={i} />
      ))}
    </div>
  );
};

const ConversationCard = ({
  conversation,
}: {
  conversation: ConversationInsightsRead;
}) => {
  const { projectId } = useParams();
  return (
    <LinkWithQuery
      to={`/${projectId}/conversations/${conversation.id}`}
      key={conversation.id}
      className="no-underline"
    >
      <Card className="h-full transition-all hover:shadow-md">
        <CardHeader className="flex-row items-center justify-between space-y-0 pb-2">
          <div className="flex items-center space-x-2 text-sm text-muted-foreground">
            <time>
              {formatDistanceToNowStrict(
                new Date(
                  Math.max(
                    ...conversation.messages.map((m) =>
                      new Date(m.created_at).getTime()
                    )
                  )
                ),
                { addSuffix: true }
              )}
            </time>
            <span>•</span>
            <div className="flex items-center space-x-1">
              <MessageSquare className="h-4 w-4" />
              <span>
                {conversation.messages.filter((m) => m.role === "user").length}
              </span>
            </div>
          </div>
        </CardHeader>
        <CardContent className="space-y-2">
          <p className="line-clamp-2 text-sm font-medium">
            {conversation.messages[0].text}
          </p>
          <p className="line-clamp-2 text-sm text-muted-foreground">
            {conversation.messages[1].text}
          </p>
        </CardContent>
      </Card>
    </LinkWithQuery>
  );
};

interface ConversationsMetadataListProps {
  projectId: number;
  since: Date | null;
  until: Date | null;
  aliases: string[];
  topics: string[];
  userIds: string[];
}
export default function ConversationsMetadataListV2({
  projectId,
  since,
  until,
  aliases,
  topics,
  userIds,
}: ConversationsMetadataListProps) {
  const {
    data,
    isPending,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    ...listConversationsInfiniteOptions({
      path: {
        project_id: projectId,
      },
      query: {
        ...(since && { since }),
        ...(until && { until }),
        topics,
        aliases,
        user_ids: userIds,
      },
    }),
    getNextPageParam: (lastPage) => lastPage.next_cursor,
    initialPageParam: 0,
  });

  const observerTarget = useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  });

  if (isPending) return <ConversationListSkeleton />;
  if (error) return <AxiosErrorBox error={error} />;

  if (data.pages[0].data.length === 0) {
    return (
      <div className="mt-12">
        <Empty message="No conversations found." />
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 lg:grid-cols-3">
        {data.pages.map((page) =>
          page.data.map((conversation) => (
            <ConversationCard
              key={conversation.id}
              conversation={conversation}
            />
          ))
        )}
      </div>

      <div ref={observerTarget} className="h-4 mt-4">
        {isFetchingNextPage && (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {[...Array(3)].map((_, i) => (
              <ConversationCardSkeleton key={i} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
