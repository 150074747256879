import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import * as Sentry from "@sentry/react";

function captureError(error: any) {
  // Only capture errors if we're not on localhost and Sentry is configured
  if (
    window.location.hostname === "localhost" ||
    !import.meta.env.VITE_SENTRY_DSN
  ) {
    console.error("Error occurred:", error);
    return;
  }

  if (error instanceof Error) {
    return Sentry.captureException(error, {
      tags: {
        path: window.location.pathname,
        errorType: error.name,
        environment: import.meta.env.VITE_ENVIRONMENT,
      },
      extra: {
        errorMessage: error.message,
        stack: error.stack,
      },
    });
  }

  // Handle non-Error objects
  return Sentry.captureMessage(String(error), {
    level: "error",
    tags: {
      path: window.location.pathname,
      errorType: typeof error,
      environment: import.meta.env.VITE_ENVIRONMENT,
    },
    extra: {
      error,
    },
  });
}

export function NotFound() {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-emerald-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, we couldn't find the page you're looking for.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className="rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
          >
            Go back home
          </a>
          <a
            href="mailto:info@botbrains.io"
            className="text-sm font-semibold text-gray-900"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
}

export default function ErrorBoundary() {
  const error = useRouteError();

  // include 404s
  captureError(error);

  // Handle 404 errors
  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFound />;
  }

  // Handle all other errors
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-emerald-600">Critical</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          An error occurred
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, an unexpected client side error occurred. Please retry. <br />{" "}
          Please reach out to{" "}
          <a className="underline font-bold" href="mailto:liam@botbrains.io">
            liam@botbrains.io
          </a>{" "}
          if the error persists.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            onClick={() => window.location.reload()}
            className="rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
          >
            Refresh
          </button>
          <a
            href="mailto:info@botbrains.io"
            className="text-sm font-semibold text-gray-900"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
}
