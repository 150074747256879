import React, { useState } from "react";
import { Upload, LoaderCircle } from "lucide-react";
import imageCompression from "browser-image-compression";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";

const SlackImageUpload = ({
  onImageUpload,
}: {
  onImageUpload: (file: File) => void;
}) => {
  const [loading, setLoading] = useState(false);
  // NOTE(memben): Prevent collisions with other instances of this component
  const randomId = Math.random().toString(36).substring(7);
  const id = `slack-image-upload-${randomId}`;

  const validateImageDimensions = (file: File) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width !== img.height) {
          reject(new Error("Image must be square (equal width and height)"));
        } else {
          resolve({
            width: img.width,
            height: img.height,
            needsCompression: img.width > 512 || img.height > 512,
          });
        }
      };
      img.onerror = () => reject(new Error("Failed to load image"));
      img.src = URL.createObjectURL(file);
    });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const validTypes = ["image/png", "image/jpeg"];
    if (!validTypes.includes(file.type)) {
      toast.error("Please upload only PNG or JPEG files");
      return;
    }

    try {
      setLoading(true);
      const dimensions: any = await validateImageDimensions(file);

      let processedFile = file;
      if (dimensions.needsCompression) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 512,
          useWebWorker: true,
          fileType: file.type,
        };
        processedFile = await imageCompression(file, options);
      }

      onImageUpload(processedFile);
    } catch (err: any) {
      toast.error(err.message || "Error processing image");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <Button
        type="button"
        size="icon"
        variant="ghost"
        onClick={() => document.getElementById(id)!.click()}
        disabled={loading}
      >
        {loading ? (
          <LoaderCircle className="h-4 w-4 animate-spin" />
        ) : (
          <Upload className="h-4 w-4" />
        )}
      </Button>

      <input
        id={id}
        type="file"
        accept=".png,.jpg,.jpeg"
        className="hidden"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default SlackImageUpload;
