import { useState } from "react";
import { Tag } from "@/client/types.gen";
import { SourceViewDialog } from "../sources/SourceViewDialog";
import { findClosestLanguageMatch } from "@/ietf-bcp-47";
import useFlagEnabled from "@/features";

function constructPosthogURL(sessionId: string, projectId = 13405): string {
  const baseUrl = `https://eu.posthog.com/project/${projectId}/events`;
  const queryObject = {
    kind: "DataTableNode",
    full: true,
    source: {
      kind: "EventsQuery",
      select: [
        "*",
        "event",
        "person",
        "coalesce(properties.$current_url, properties.$screen_name) -- Url / Screen",
        "properties.$lib",
        "timestamp",
      ],
      orderBy: ["timestamp DESC"],
      after: "-24h",
      properties: [
        {
          key: "$session_id",
          value: [sessionId],
          operator: "exact",
          type: "event",
        },
      ],
    },
    propertiesViaUrl: true,
    showSavedQueries: true,
    showPersistentColumnConfigurator: true,
  };

  return `${baseUrl}#q=${encodeURIComponent(JSON.stringify(queryObject))}`;
}

function constructDatadogURL(
  sessionId: string,
  applicationId = "2075098b-155f-4050-b4bb-1bbd7b4af755"
): string {
  const baseUrl = "https://app.datadoghq.eu/rum/sessions";
  const queryParams = new URLSearchParams({
    query: `@type:session @application.id:${applicationId} @session.id:${sessionId}`,
  });
  return `${baseUrl}?${queryParams.toString()}`;
}

interface ColorConfig {
  circle: string;
  text?: string;
  border?: string;
  background?: string;
}

interface TagStyleConfig {
  colors: ColorConfig | Record<string, ColorConfig>;
  label?: string | ((value: string) => string);
  href?: (value: string) => string;
  render?: (props: TagRenderProps) => JSX.Element;
}

interface TagRenderProps {
  value: string;
  colors: ColorConfig;
  label: string;
  className?: string;
}

function SourceTag({ value, colors, label, className }: TagRenderProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => setDialogOpen(true)}
        className={className}
      >
        <svg className={`h-1.5 w-1.5 ${colors.circle}`} viewBox="0 0 6 6">
          <circle cx={3} cy={3} r={3} />
        </svg>
        {label}
      </button>

      <SourceViewDialog
        sourceId={Number(value)}
        open={dialogOpen}
        onOpenChange={setDialogOpen}
      />
    </>
  );
}

const TAG_CONFIG: Record<string, TagStyleConfig> = {
  "api:tracing_url": {
    colors: { circle: "fill-blue-500" },
    label: "Tracing URL",
    href: (value) => value,
  },
  "api:source": {
    colors: { circle: "fill-purple-500" },
    label: (value) => `Source #${value}`,
    render: ({ value, colors, label, className }) => (
      <SourceTag
        value={value}
        colors={colors}
        label={label}
        className={className}
      />
    ),
  },
  "chat:website.url": {
    colors: { circle: "fill-blue-400" },
    label: (value: string) => {
      const url = new URL(value);
      url.search = "";
      url.hash = "";
      return `🌐 ${url.toString()}`;
    },
    href: (value) => value,
  },
  "chat:rating": {
    colors: { circle: "fill-green-500" },
    label: (value: string) =>
      `User rated ${value === "thumbsUp" ? "👍" : "👎"}`,
  },
  "chat:posthog.distinctId": {
    colors: { circle: "fill-[#f0b429]" },
    label: "Posthog Session",
    href: (value) => constructPosthogURL(value),
  },
  "chat:datadog.sessionId": {
    colors: { circle: "fill-[#774aa4]" },
    label: "Datadog Session",
    href: (value) => constructDatadogURL(value),
  },
  "nlp:assistant.completeness": {
    colors: {
      Incomplete: {
        circle: "fill-red-500",
        text: "text-red-700",
        background: "bg-red-50",
      },
      Clarifying: {
        circle: "fill-yellow-500",
        text: "text-yellow-700",
        background: "bg-yellow-50",
      },
      Partial: {
        circle: "fill-green-500",
        text: "text-green-700",
        background: "bg-green-50",
      },
      Complete: {
        circle: "fill-green-700",
        text: "text-green-900",
        background: "bg-green-100",
      },
    },
    label: (value: string) => `Response ${value}`,
  },
  "nlp:user.sentiment": {
    colors: {
      Negative: {
        circle: "fill-red-500",
        text: "text-red-700",
        background: "bg-red-50",
      },
      Neutral: {
        circle: "fill-gray-500",
        text: "text-gray-700",
        background: "bg-gray-50",
      },
      Positive: {
        circle: "fill-green-500",
        text: "text-green-700",
        background: "bg-green-50",
      },
    },
    label: (value: string) => `Sentiment: ${value}`,
  },
  "nlp:user.language": {
    colors: {
      circle: "fill-indigo-500",
      text: "text-indigo-700",
      background: "bg-indigo-50",
      border: "ring-indigo-200",
    },
    label: (value: string) => {
      const langInfo = findClosestLanguageMatch(value);
      return `${langInfo?.emoji} ${langInfo?.shortLocalizedName}`;
    },
  },
  "nlp:assistant.language": {
    colors: {
      circle: "fill-indigo-500",
      text: "text-indigo-700",
      background: "bg-indigo-50",
      border: "ring-indigo-200",
    },
    label: (value: string) => {
      const langInfo = findClosestLanguageMatch(value);
      return `${langInfo?.emoji} ${langInfo?.shortLocalizedName}`;
    },
  },
  // Conversation Tags
  "slack:channel_name": {
    colors: {
      circle: "fill-[#4A154B]",
      text: "text-[#4A154B]",
      background: "bg-[#F7EBFF]",
      border: "ring-[#E9D8FF]",
    },
    label: (value: string) => `Slack #${value}`,
  },
  "slack:channel_type": {
    colors: {
      circle: "fill-[#4A154B]",
      text: "text-[#4A154B]",
      background: "bg-[#F7EBFF]",
      border: "ring-[#E9D8FF]",
    },
    label: (value: string) => {
      switch (value) {
        case "channel":
          return "Public Channel";
        case "group":
          return "Private Channel";
        case "im":
          return "Direct Message";
        default:
          return value;
      }
    },
  },
  "slack:thread_ts": {
    colors: {
      circle: "fill-[#4A154B]",
      text: "text-[#4A154B]",
      background: "bg-[#F7EBFF]",
      border: "ring-[#E9D8FF]",
    },
    label: "In Thread",
  },
};

const publicTags = [
  "nlp:user.language",
  "nlp:assistant.language",
  "chat:rating",
  "nlp:assistant.completeness",
  "nlp:user.sentiment",
  "chat:website.url",
  // Conversation Tags
  "slack:channel_name",
  "slack:channel_type",
  "slack:thread_ts",
];

function TagComponent({ tag }: { tag: Tag }) {
  const config = TAG_CONFIG[tag.name];
  if (!config) {
    return (
      <span className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
        <svg className="h-1.5 w-1.5 fill-gray-500" viewBox="0 0 6 6">
          <circle cx={3} cy={3} r={3} />
        </svg>
        {tag.name} = {tag.value}
      </span>
    );
  }

  const colorStyles =
    "circle" in config.colors
      ? (config.colors as ColorConfig)
      : config.colors[tag.value] || {
          circle: "fill-gray-500",
          text: "text-gray-900",
          background: "bg-white",
          border: "ring-gray-200",
        };

  const label =
    typeof config.label === "function" ? config.label(tag.value) : config.label;
  const href = config.href?.(tag.value);

  const className = `inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset 
    ${colorStyles.background || "bg-white"} 
    ${colorStyles.text || "text-gray-900"} 
    ${colorStyles.border || "ring-gray-200"}
    ${href ? "cursor-pointer hover:bg-gray-50" : ""}`;

  // Use custom render function if provided
  if (config.render) {
    return config.render({
      value: tag.value,
      colors: colorStyles,
      label: label || `${tag.name} = ${tag.value}`,
      className,
    });
  }

  // Default rendering for href tags
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        title={label || `${tag.name} = ${tag.value}`}
      >
        <svg className={`h-1.5 w-1.5 ${colorStyles.circle}`} viewBox="0 0 6 6">
          <circle cx={3} cy={3} r={3} />
        </svg>
        <span className="truncate max-w-96 lg:max-w-xl">
          {label || `${tag.name} = ${tag.value}`}
        </span>
      </a>
    );
  }

  // Default rendering for regular tags
  return (
    <span className={className}>
      <svg className={`h-1.5 w-1.5 ${colorStyles.circle}`} viewBox="0 0 6 6">
        <circle cx={3} cy={3} r={3} />
      </svg>
      {label || `${tag.name} = ${tag.value}`}
    </span>
  );
}

export function Tags({ tags }: { tags: Tag[] }) {
  const viewAllTags = useFlagEnabled("view-all-tags");

  const viewableTags = tags
    .filter((tag) => publicTags.includes(tag.name) || viewAllTags)
    .sort((a, b) => {
      const aIndex = publicTags.indexOf(a.name);
      const bIndex = publicTags.indexOf(b.name);
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });

  return (
    <div className="flex flex-wrap gap-2 text-slate-500">
      {viewableTags.map((tag, index) => (
        <TagComponent key={index} tag={tag} />
      ))}
    </div>
  );
}
